<template>
  <div>
    <div
      v-if="loading"
      class="d-flex"
    >
      <b-skeleton
        width="100%"
        height="40px"
        class="mb-0"
      />
    </div>
    <v-select
      v-else
      v-model="selected"
      label="name"
      :options="filteredOptions"
      placeholder="Cari project..."
      :clearable="false"
      @option:selected="onSelected"
    >
      <template #list-header>
        <div
          v-b-toggle.sidebar.sidebar-project
          class="d-flex justify-content-between align-items-center cursor-pointer p-1 add-button"
          @mouseup.stop
        >
          <h6 class="text-primary m-0">
            Tambah Project Baru
          </h6>
          <feather-icon
            icon="PlusIcon"
            size="21"
            class="text-primary"
          />
        </div>
      </template>
      <template #option="project">
        <span class="font-weight-bold mt-25 d-block">{{ project.name }}</span>
        <small class="text-muted">
          Tanggal Reminder : {{ formatDate(project.tgl_reminder) }}
        </small>
      </template>
      <template #no-options>
        <h6 class="text-muted font-weight-normal">
          Tidak ada project yang ditemukan
        </h6>
      </template>
    </v-select>

    <b-sidebar
      id="sidebar-project"
      ref="sidebarProject"
      lazy
      title="Tambah Project"
      right
      shadow
      backdrop
      backdrop-variant="transparent"
      class="sidebar"
    >
      <div class="px-2 py-1">
        <validation-observer ref="validateQuickProject">
          <b-form @submit.prevent>
            <b-form-group
              label="Customer"
            >
              <validation-provider
                #default="{ errors }"
                name="Customer"
                rules="required"
              >
                <customer-auto-suggest
                  v-model="projectData.customer"
                  :disabled="filterCustomer.id !== null"
                  @onSelected="handleSelected"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Nama Project"
            >
              <validation-provider
                #default="{ errors }"
                name="Nama project"
                rules="required|min:10|max:255"
              >
                <b-form-textarea
                  v-model="projectData.projectName"
                  placeholder="Nama project"
                  rows="3"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Tanggal Reminder"
            >
              <b-form-datepicker
                v-model="projectData.reminderDate"
                today-button
                reset-button
                locale="id-ID"
                placeholder="Pilih tanggal"
                @context="onContext"
              />
            </b-form-group>
            <b-form-group
              label="Kategori"
            >
              <validation-provider
                #default="{ errors }"
                name="Kategori"
                rules="required"
              >
                <v-select
                  v-model="projectData.category"
                  label="name"
                  :options="projectCategory"
                  placeholder="Kategori"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              variant="primary"
              @click="loadingAdd ? null : submitProject()"
            >
              <span v-if="!loadingAdd">Submit</span>
              <b-spinner
                v-else
                small
              />
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              block
              class="mt-1"
              @click="resetForm"
            >
              Reset
            </b-button>
          </b-form>
        </validation-observer>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, VBToggle, BForm, BFormGroup, BButton, BFormTextarea, BFormDatepicker, BSpinner, BSkeleton,
} from 'bootstrap-vue'
import CustomerAutoSuggest from '@/components/input/CustomerAutoSuggest.vue'
import vSelect from 'vue-select'
import { avatarText, formatDate } from '@core/utils/filter'
import { mapGetters, mapActions } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, min, max,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { projects } from '@/graphql/query'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BButton,
    BFormTextarea,
    BFormDatepicker,
    BSpinner,
    BSkeleton,
    vSelect,
    ValidationObserver,
    ValidationProvider,
    CustomerAutoSuggest,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    filterCustomer: {
      type: Object,
      default: () => {},
    },
    activeOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      min,
      max,
      loading: false,
      avatarText,
      filteredOptions: [],
      data: '',
      loadingAdd: false,
      projectData: {
        customer: '',
        projectName: '',
        reminderDate: '',
        category: '',
      },
      formattedDate: '',
    }
  },
  computed: {
    ...mapGetters({
      datasuggest: 'getProjectList',
      projectCategory: 'getProjectCategory',
    }),
    selected: {
      get() {
        let value = ''
        if (this.value) {
          const preselected = this.filteredOptions.filter(el => el.id === this.value.id)

          value = preselected.length > 0 ? preselected[0].name : ''
        }
        return value
      },
      set(val) {
        this.data = val
      },
    },
  },
  async mounted() {
    if (this.filterCustomer.id) {
      this.projectData.customer = this.filterCustomer
    }
    this.fetchProject()

    if (this.projectCategory.length < 1) {
      this.$store.dispatch('getProjectCategory')
    }
  },
  methods: {
    formatDate,
    onContext(ctx) {
      this.formattedDate = ctx.selectedFormatted
    },
    handleSelected(data) {
      this.projectData.customer = data
    },
    ...mapActions(['getAllProject']),
    onSelected(option) {
      if (option) {
        this.$emit('onSelected', option)
      }
    },
    async fetchProject(search) {
      this.loading = true
      const keyword = search ? `"${search.toLowerCase()}"` : null
      const customer = this.filterCustomer.id ? `${this.filterCustomer.id}` : null

      this.$apollo.query({
        query: projects,
        context: {
          headers: {
            Authorization: this.$store.getters.getBearerToken,
          },
        },
        variables: {
          filter: {
            customer_id: customer,
            search: keyword,
            status: this.activeOnly ? 1 : null,
          },
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        this.filteredOptions = result.data.projects.projects
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    submitProject() {
      if (!this.projectData.customer) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Pilih customer terlebih dahulu!',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
      } else if (!this.projectData.category) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Pilih kategori terlebih dahulu!',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
      } else if (!this.projectData.projectName) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Isi nama project terlebih dahulu!',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
      } else {
        this.loadingAdd = true
        this.$store.dispatch('getAreaCategory', this.projectData.category.id).then(category => {
          this.$store.dispatch('addProject', {
            customerId: this.projectData.customer.id,
            projectName: this.projectData.projectName,
            category: this.projectData.category.id,
            reminderDate: new Date(this.projectData.reminderDate),
            surfaceArea: category.areaCategory.length ? category.areaCategory.map(el => ({
              area_category_id: el.id,
              surface_area: 0,
            })) : null,
          }).then(() => {
            this.loadingAdd = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Project berhasil ditambah!',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            this.fetchProject()
            this.$refs.sidebarProject.hide()
            this.resetForm()
          }).catch(() => {
            this.loadingAdd = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Gagal menyimpan!',
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
          })
        })
      }
    },
    resetForm() {
      if (this.filterCustomer.id === null) {
        this.projectData.customer = ''
      }

      this.projectData.projectName = ''
      this.projectData.reminderDate = ''
      this.projectData.category = ''

      this.$refs.validateQuickProject.reset()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/base/components/variables-dark';

.add-button:hover {
  background: #eee;

  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}

.b-sidebar {
  background-color: #fff !important;

  .dark-layout & {
    background-color: $theme-dark-body-bg !important;
  }

  width: 360px;
}
</style>
