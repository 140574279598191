<template>
  <div class="invoice-add-wrapper">
    <validation-observer
      v-if="loading !== 'error'"
      ref="validateQuote"
    >
      <back-navigation
        :title="reviseMode ? 'Revise Quote' : editMode ? 'Edit Quote' : 'Quote Baru'"
      >
        <template #action>
          <div class="d-flex justify-content-end ml-auto">
            <XyzTransition
              appear
              xyz="fade right-3 delay-2"
            >
              <b-button
                v-if="!editMode && dirtyForm"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                variant="outline-secondary"
                @click="confirmReset"
              >
                Reset
              </b-button>
            </XyzTransition>
          </div>
        </template>
      </back-navigation>

      <div
        v-if="loading"
        key="1"
        class="d-flex align-items-center justify-content-center"
        style="height: 80vh"
      >
        <b-spinner variant="primary" />
      </div>
      <b-form
        v-else
        @submit.prevent
      >
        <b-row class="invoice-add">
          <b-col
            cols="12"
            xl="8"
          >
            <XyzTransition
              appear
              xyz="fade left-3 delay-2"
            >
              <b-card
                no-body
                class="invoice-preview-card"
              >
                <b-card-body>
                  <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
                    <div>
                      <div
                        v-if="selecteddb"
                      >
                        <b-img
                          height="30"
                          :src="selecteddb.photo_long"
                        />
                        <h6 class="text-muted mt-1">
                          {{ selecteddb.address }}
                        </h6>
                      </div>
                      <h3
                        v-else
                        class="font-weight-bolder text-primary"
                      >
                        Quotation
                      </h3>
                      <b-form-textarea
                        v-model="quotationData.notes"
                        class="mt-1"
                        style="min-width: 240px"
                        placeholder="Notes"
                        rows="2"
                      />
                    </div>
                    <div class="invoice-number-date mt-md-0 mt-2">
                      <div class="d-flex align-items-center mb-1">
                        <span class="title">
                          Tanggal:
                        </span>
                        <flat-pickr
                          v-model="quotationData.issuedDate"
                          disabled
                          class="form-control invoice-edit-input"
                        />
                      </div>
                      <div class="d-flex align-items-center">
                        <span class="title">
                          Berlaku sampai:
                        </span>
                        <flat-pickr
                          v-model="quotationData.dueDate"
                          :config="{
                            minDate: quotationData.issuedDate
                          }"
                          class="form-control invoice-edit-input"
                          placeholder="Pilih tanggal"
                        />
                      </div>
                    </div>
                  </div>
                </b-card-body>
                <hr class="m-0">
                <b-card-body>
                  <b-row>
                    <b-col
                      cols="12"
                      xl="6"
                    >
                      <b-form-group label="Untuk Customer : ">
                        <validation-provider
                          #default="{ errors }"
                          name="Customer"
                          rules="required"
                        >
                          <customer-auto-suggest
                            v-model="quotationData.customer"
                            @onSelected="handleCustomerSelected"
                          />
                          <small class="text-danger ml-auto">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      xl="6"
                    >
                      <b-collapse :visible="quotationData.customer.id !== undefined">
                        <b-form-group label="Project :">
                          <validation-provider
                            #default="{ errors }"
                            name="Project"
                            rules="required"
                          >
                            <project-auto-suggest
                              :key="quotationData.customer.id+quotationData.project.id"
                              v-model="quotationData.project"
                              :filter-customer="quotationData.customer"
                              active-only
                              @onSelected="handleProjectSelected"
                            />
                            <small class="text-danger ml-auto">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-collapse>
                    </b-col>
                  </b-row>
                </b-card-body>
                <b-collapse :visible="quotationData.project.id ? true : false">
                  <hr class="m-0">
                  <b-card-body>
                    <h5>Detail Area dan Item</h5>
                    <b-form-group
                      :class="{'mb-2' : selectedArea.length}"
                      label="Pilih Area"
                    >
                      <div
                        v-if="loadingAreaOptions"
                        class="d-flex"
                      >
                        <b-skeleton
                          width="100%"
                          height="40px"
                          class="mb-0"
                        />
                      </div>
                      <v-select
                        v-else
                        v-model="selectedAreaVModel"
                        label="name"
                        :options="areaOptions"
                        placeholder="Pilih Area"
                        multiple
                        :map-keydown="preventDelete"
                        @option:selected="handleSelectedArea"
                        @option:deselected="handleDeselectedArea"
                      />
                    </b-form-group>
                    <b-collapse :visible="selectedArea.length > 0">
                      <div
                        v-for="(area, index) in selectedArea"
                        :key="area.id"
                      >
                        <b-card
                          v-if="area.name.toLowerCase() !== 'free item' && area.name.toLowerCase() !== 'anchor'"
                          border-variant="light"
                          class="custom-shadow mt-2"
                        >
                          <div class="d-flex justify-content-between align-items-center mb-1">
                            <div>
                              <small class="text-muted">Area {{ index + 1 }}</small>
                              <h5>
                                {{ area.name }}
                              </h5>
                            </div>
                            <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              class="align-self-baseline"
                              variant="outline-danger"
                              @click="handleDeselectedArea(area)"
                            >
                              <feather-icon icon="XIcon" />
                              Hapus
                            </b-button>
                          </div>
                          <b-row>
                            <b-col
                              cols="12"
                              xl="6"
                            >
                              <small>
                                Permukaan
                              </small>
                              <v-select
                                v-model="selectedArea[index].selectedSurface"
                                class="w-100 mt-25"
                                label="name"
                                :options="surfaceOptions"
                                placeholder="Pilih Permukaan"
                                multiple
                              />
                            </b-col>
                            <b-col
                              cols="12"
                              xl="6"
                              class="mt-1 mt-xl-0 ml-auto"
                            >
                              <b-row>
                                <b-col
                                  cols="6"
                                  class="pr-50"
                                >
                                  <small>
                                    Luas Area
                                  </small>
                                  <validation-provider
                                    #default="{ errors }"
                                    :name="`Luas Area ${index + 1}`"
                                    rules="integer|min_value:1|max_value:99999|positive|required"
                                    class="w-100"
                                  >
                                    <b-input-group
                                      append="M2"
                                      class="input-group-merge mt-25"
                                    >
                                      <b-form-input
                                        v-model="selectedArea[index].luasArea"
                                        type="number"
                                        min="1"
                                        max="99999"
                                        placeholder="Luas Area"
                                        @input="changeStuff(false)"
                                      />
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-col>
                                <b-col
                                  cols="6"
                                  class="pl-50"
                                >
                                  <small
                                    v-b-popover.hover.top="'Atur jumlah loss untuk semua item di area ini'"
                                  >
                                    Loss
                                  </small>
                                  <validation-provider
                                    #default="{ errors }"
                                    :name="`Loss ${index + 1}`"
                                    rules="integer|min_value:0|max_value:100|positive|required"
                                    class="w-100"
                                  >
                                    <b-input-group
                                      append="%"
                                      class="input-group-merge mt-25"
                                    >
                                      <b-form-input
                                        v-model="selectedArea[index].loss"
                                        type="number"
                                        placeholder="100"
                                        @input="replaceAllLoss(index)"
                                      />
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                          <small
                            class="text-primary cursor-pointer d-block mt-1"
                            @click="selectedArea[index].showNotes = !selectedArea[index].showNotes"
                          >Tambah Notes Area</small>
                          <b-collapse
                            :visible="selectedArea[index].showNotes"
                          >
                            <b-form-textarea
                              v-model="selectedArea[index].notes"
                              class="mt-1"
                              style="min-width: 240px"
                              placeholder="Notes"
                              rows="1"
                            />
                          </b-collapse>
                          <scheme-item-auto-suggest
                            class="mb-1 mt-1"
                            @onSelected="handleSelectedItem($event, index)"
                          />
                          <b-overlay :show="selectedArea[index].loadingArea">
                            <vue-good-table
                              :columns="itemTableColumn"
                              :rows="selectedArea[index].selectedItem"
                              :sort-options="{ enabled: true, initialSortBy: {field: 'sort', type: 'asc'} }"
                              :pagination-options="{
                                enabled: false,
                              }"
                              style-class="vgt-table mt-1"
                            >
                              <div slot="emptystate">
                                <small class="text-center d-block">Pilih item terlebih dahulu</small>
                              </div>
                              <template
                                slot="table-row"
                                slot-scope="props"
                              >
                                <span v-if="props.column.field === 'action'">
                                  <b-img
                                    v-if="props.row.data_sheet"
                                    v-b-popover.hover.top="'Data Sheet'"
                                    class="cursor-pointer mr-1"
                                    :src="require('@/assets/images/icons/pdf.png')"
                                    height="auto"
                                    width="18"
                                    @click="$refs.pdf.show(props.row.data_sheet)"
                                  />
                                  <feather-icon
                                    icon="Trash2Icon"
                                    class="text-danger cursor-pointer mr-2"
                                    size="20"
                                    @click="deleteItem(index, props.row)"
                                  />
                                </span>
                                <span v-else-if="props.column.field === 'name'">
                                  <div class="d-flex align-items-center">
                                    <div>
                                      <h6 class="m-0">{{ props.row.name }}</h6>
                                      <small class="text-secondary d-block">
                                        {{ props.row.no }} {{ props.row.liter ? `(${props.row.liter} liter)` : '' }}
                                      </small>
                                      <small
                                        v-if="props.row.agentItem1 || props.row.agentItem2"
                                        class="font-weight-bold"
                                      >
                                        {{ findSetLiter(props.row) }}
                                      </small>
                                    </div>
                                    <b-avatar
                                      v-if="props.row.agentItem1 || props.row.agentItem2"
                                      :id="`popover-${index}-${props.row.originalIndex}`"
                                      variant="light-primary"
                                      :size="28"
                                      class="ml-75"
                                    >
                                      <feather-icon
                                        icon="LinkIcon"
                                        size="16"
                                      />
                                    </b-avatar>
                                    <b-popover
                                      v-if="props.row.agentItem1 || props.row.agentItem2"
                                      :target="`popover-${index}-${props.row.originalIndex}`"
                                      triggers="hover focus"
                                      placement="topright"
                                      boundary="viewport"
                                    >
                                      <small class="text-primary">
                                        Berpasangan dengan agent item
                                      </small>
                                      <table
                                        border="0"
                                        class="mt-75"
                                        style="border-color: #dbdbdb"
                                      >
                                        <tr>
                                          <th class="pr-75">
                                            Item
                                          </th>
                                          <th>
                                            Vol.
                                          </th>
                                        </tr>
                                        <tr>
                                          <td class="py-50 pr-1">
                                            <small class="font-weight-bold d-block">
                                              {{ props.row.name }}
                                            </small>
                                            <small class="text-muted">
                                              {{ props.row.no }} ({{ props.row.liter }} liter)
                                            </small>
                                          </td>
                                          <td class="py-50">
                                            <h6 class="m-0">
                                              {{
                                                props.row[`volume${index}`] && props.row.agentItem1 && props.row.agentItem2
                                                  ? +props.row[`volume${index}`] - +props.row.agentItem1[`volume${index}`] - +props.row.agentItem2[`volume${index}`] + 'L'
                                                  : props.row[`volume${index}`] && props.row.agentItem1
                                                    ? +props.row[`volume${index}`] - +props.row.agentItem1[`volume${index}`] + 'L'
                                                    : props.row[`volume${index}`]
                                                      ? props.row[`volume${index}`] + 'L'
                                                      : '0L'
                                              }}
                                            </h6>
                                          </td>
                                        </tr>
                                        <tr
                                          v-if="props.row.agentItem1"
                                        >
                                          <td class="py-50 pr-1">
                                            <small class="font-weight-bold d-block">
                                              {{ props.row.agentItem1.name }}
                                            </small>
                                            <small class="text-muted">
                                              {{ props.row.agentItem1.no }} ({{ props.row.agentItem1.liter }} liter)
                                            </small>
                                          </td>
                                          <td class="py-50">
                                            <h6 class="m-0">
                                              {{ props.row.agentItem1[`volume${index}`] ? props.row.agentItem1[`volume${index}`] + 'L' : '0L' }}
                                            </h6>
                                          </td>
                                        </tr>
                                        <tr
                                          v-if="props.row.agentItem2"
                                        >
                                          <td class="py-50 pr-1">
                                            <small class="font-weight-bold d-block">
                                              {{ props.row.agentItem2.name }}
                                            </small>
                                            <small class="text-muted">
                                              {{ props.row.agentItem2.no }} ({{ props.row.agentItem2.liter }} liter)
                                            </small>
                                          </td>
                                          <td class="py-50">
                                            <h6 class="m-0">
                                              {{ props.row.agentItem2[`volume${index}`] ? props.row.agentItem2[`volume${index}`] + 'L' : '0L' }}
                                            </h6>
                                          </td>
                                        </tr>
                                      </table>
                                    </b-popover>
                                  </div>
                                </span>
                                <span v-else-if="props.column.field === 'color'">
                                  <div v-if="props.row.type.id == 1 && props.row.color">
                                    <div class="d-flex align-center">
                                      <div
                                        v-if="props.row.color.hex_code"
                                        v-b-popover.hover.top="`#${props.row.color.hex_code}`"
                                        class="mr-50"
                                        :style="`height: 36px; width: 6px; border-radius: 2px; background: #${props.row.color.hex_code}`"
                                      />
                                      <div>
                                        <h6 class="m-0">{{ props.row.color.eng_name }}</h6>
                                        <small>{{ props.row.color.id_ral }}</small>
                                      </div>
                                    </div>
                                  </div>
                                </span>
                                <span v-else-if="props.column.field === 'coat'">
                                  <div v-if="props.row.type.id == 1">
                                    <validation-provider
                                      #default="{ errors }"
                                      :name="`Coat ${index + 1} - ${props.row.originalIndex}`"
                                      rules="integer|min_value:0|max_value:100|required"
                                    >
                                      <b-input-group
                                        append="%"
                                        class="input-group-merge"
                                      >
                                        <b-form-input
                                          v-model="selectedArea[index].selectedItem[props.row.originalIndex][`coat${index}`]"
                                          type="number"
                                          placeholder="100"
                                          @input="changeStuff(selectedArea[index].selectedItem[props.row.originalIndex][`totalChanged${index}`] !== null ? true : false)"
                                        />
                                      </b-input-group>
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                  </div>
                                </span>
                                <span v-else-if="props.column.field === 'dft'">
                                  <div v-if="props.row.type.id == 1">
                                    <validation-provider
                                      #default="{ errors }"
                                      :name="`DFT ${index + 1} - ${props.row.originalIndex}`"
                                      rules="integer|min_value:0|max_value:1000|required"
                                    >
                                      <b-input-group
                                        append="μm"
                                        class="input-group-merge"
                                      >
                                        <b-form-input
                                          v-model="selectedArea[index].selectedItem[props.row.originalIndex][`dft${index}`]"
                                          type="number"
                                          placeholder="100"
                                          @input="changeStuff(selectedArea[index].selectedItem[props.row.originalIndex][`totalChanged${index}`] !== null ? true : false)"
                                        />
                                      </b-input-group>
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                  </div>
                                </span>
                                <span v-else-if="props.column.field === 'loss'">
                                  <div v-if="props.row.type.id == 1">
                                    <validation-provider
                                      #default="{ errors }"
                                      :name="`Loss ${index + 1} - ${props.row.originalIndex}`"
                                      rules="integer|min_value:0|max_value:100|positive|required"
                                    >
                                      <b-input-group
                                        append="%"
                                        class="input-group-merge"
                                      >
                                        <b-form-input
                                          v-model="selectedArea[index].selectedItem[props.row.originalIndex][`loss${index}`]"
                                          type="number"
                                          placeholder="20"
                                          @input="changeStuff(selectedArea[index].selectedItem[props.row.originalIndex][`totalChanged${index}`] !== null ? true : false)"
                                        />
                                      </b-input-group>
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                  </div>
                                </span>
                                <span v-else-if="props.column.field === 'wft'">
                                  <div v-if="props.row.type.id == 1">
                                    {{ selectedArea[index].selectedItem[props.row.originalIndex][`wft${index}`] }}
                                  </div>
                                </span>
                                <span v-else-if="props.column.field === 'theory'">
                                  <div v-if="props.row.type.id == 1">
                                    {{ selectedArea[index].selectedItem[props.row.originalIndex][`theory${index}`] }}
                                  </div>
                                </span>
                                <span v-else-if="props.column.field === 'practical'">
                                  <div v-if="props.row.type.id == 1">
                                    {{ selectedArea[index].selectedItem[props.row.originalIndex][`practical${index}`] }}
                                  </div>
                                </span>
                                <span v-else-if="props.column.field === 'thinner'">
                                  <div v-if="props.row.type.id == 1 && props.row.thinner">
                                    <h6 class="m-0">{{ props.row.thinner.name }}</h6>
                                    <small>{{ props.row.thinner.no }}</small>
                                  </div>
                                </span>
                                <span v-else-if="props.column.field === 'volume'">
                                  <validation-provider
                                    #default="{ errors }"
                                    :name="`Volume ${index + 1} - ${props.row.originalIndex}`"
                                    :rules="`min_value:0|max_value:9999999|required|twodecimal|validatevol:${area.luasArea},${findSetLiter(props.row, true)},${+props.row[`volume${index}`]},${area.id}`"
                                  >
                                    <b-input-group
                                      class="input-group-merge"
                                    >
                                      <b-form-input
                                        v-model="selectedArea[index].selectedItem[props.row.originalIndex][`volume${index}`]"
                                        type="number"
                                        placeholder="Liter"
                                        @input="volumeChange($event, index, props.row.originalIndex, false)"
                                      />
                                      <b-input-group-append is-text>
                                        <feather-icon
                                          v-b-popover.hover.top="`Volume asli sebelum dibulatkan ke ratio kemasan : ${selectedArea[index].selectedItem[props.row.originalIndex][`realVolume${index}`] ? selectedArea[index].selectedItem[props.row.originalIndex][`realVolume${index}`] : selectedArea[index].selectedItem[props.row.originalIndex][`thinnerRealVolume${index}`] ? selectedArea[index].selectedItem[props.row.originalIndex][`thinnerRealVolume${index}`].toFixed(2) : 0 } liter.`"
                                          class="text-primary"
                                          icon="InfoIcon"
                                        />
                                      </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </span>
                                <span v-else-if="props.column.field === 'price'">
                                  <validation-provider
                                    #default="{ errors }"
                                    :name="`Harga ${index + 1} - ${props.row.originalIndex}`"
                                    rules="min_value:10000|max_value:9999999|required|twodecimal"
                                  >
                                    <b-input-group
                                      prepend="Rp."
                                      class="input-group-merge"
                                    >
                                      <b-form-input
                                        v-model="selectedArea[index].selectedItem[props.row.originalIndex][`price${index}`]"
                                        type="number"
                                        placeholder="Harga"
                                        @input="getTotal(index, props.row.originalIndex)"
                                      />
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                  <div v-if="permission.viewHpp">
                                    <label class="mt-50 text-secondary">HPP : </label>
                                    <h6 class="m-0">
                                      {{ formatCurrency(selectedArea[index].selectedItem[props.row.originalIndex][`hpp${index}`]) }}
                                    </h6>
                                  </div>
                                </span>
                                <span v-else-if="props.column.field === 'total'">
                                  <h6 class="m-0">{{ formatCurrency(selectedArea[index].selectedItem[props.row.originalIndex][`total${index}`]) }}</h6>
                                  <small v-if="permission.viewHpp">{{ formatCurrency(selectedArea[index].selectedItem[props.row.originalIndex][`totalhpp${index}`]) }}<span class="text-muted ml-25">(HPP)</span></small>
                                </span>
                              </template>
                              <div
                                slot="table-actions-bottom"
                                class="p-1"
                              >
                                <b-row>
                                  <b-col
                                    class="d-flex"
                                    cols="12"
                                    md="6"
                                  >
                                    <h6 class="text-muted mr-1">
                                      Subtotal DFT
                                    </h6>
                                    <h6>{{ selectedArea[index].totalDFT }} μm</h6>
                                  </b-col>
                                  <b-col
                                    class="d-flex"
                                    cols="12"
                                    md="6"
                                  >
                                    <h6 class="text-muted mr-1">
                                      Subtotal Vol.
                                    </h6>
                                    <h6>{{ selectedArea[index].totalVol }}</h6>
                                  </b-col>
                                  <b-col
                                    class="d-flex"
                                    cols="12"
                                    md="6"
                                  >
                                    <h6 class="text-muted mr-1">
                                      Subtotal
                                    </h6>
                                    <h6>{{ formatCurrency(selectedArea[index].total) }}</h6>
                                  </b-col>
                                  <b-col
                                    v-if="permission.viewHpp"
                                    class="d-flex"
                                    cols="12"
                                    md="6"
                                  >
                                    <h6 class="text-muted mr-1">
                                      Subtotal HPP
                                    </h6>
                                    <h6>{{ formatCurrency(selectedArea[index].totalhpp) }}</h6>
                                  </b-col>
                                </b-row>
                              </div>
                            </vue-good-table>
                          </b-overlay>
                        </b-card>
                        <b-card
                          v-else
                          border-variant="light"
                          class="custom-shadow mt-2"
                        >
                          <div class="d-flex justify-content-between align-items-center mb-1">
                            <div>
                              <small class="text-muted">Area {{ index + 1 }}</small>
                              <h5>
                                {{ area.name }}
                              </h5>
                            </div>
                            <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              class="align-self-baseline"
                              variant="outline-danger"
                              @click="handleDeselectedArea(area)"
                            >
                              <feather-icon icon="XIcon" />
                              Hapus
                            </b-button>
                          </div>
                          <small
                            class="text-primary cursor-pointer d-block mt-1"
                            @click="selectedArea[index].showNotes = !selectedArea[index].showNotes"
                          >Tambah Notes Area</small>
                          <b-collapse
                            :visible="selectedArea[index].showNotes"
                          >
                            <b-form-textarea
                              v-model="selectedArea[index].notes"
                              class="mt-1"
                              style="min-width: 240px"
                              placeholder="Notes"
                              rows="1"
                            />
                          </b-collapse>
                          <item-auto-suggest
                            class="mb-25 mt-1"
                            :filter-type="area.name.toLowerCase() === 'free item' ? [1, 3] : [1]"
                            @onSelected="handleSelectedFreeItem($event, index)"
                          />
                          <b-overlay :show="selectedArea[index].loadingArea">
                            <vue-good-table
                              :columns="freeItemTableColumn"
                              :rows="selectedArea[index].selectedItem"
                              :sort-options="{ enabled: true, initialSortBy: {field: 'sort', type: 'asc'} }"
                              :pagination-options="{
                                enabled: false,
                              }"
                              style-class="vgt-table mt-1"
                            >
                              <div slot="emptystate">
                                <small class="text-center d-block">Pilih item terlebih dahulu</small>
                              </div>
                              <template
                                slot="table-row"
                                slot-scope="props"
                              >
                                <span v-if="props.column.field === 'action'">
                                  <b-img
                                    v-if="props.row.data_sheet"
                                    v-b-popover.hover.top="'Data Sheet'"
                                    class="cursor-pointer mr-1"
                                    :src="require('@/assets/images/icons/pdf.png')"
                                    height="auto"
                                    width="18"
                                    @click="$refs.pdf.show(props.row.data_sheet)"
                                  />
                                  <feather-icon
                                    icon="Trash2Icon"
                                    class="text-danger cursor-pointer mr-2"
                                    size="20"
                                    @click="deleteItem(index, props.row)"
                                  />
                                </span>
                                <span v-else-if="props.column.field === 'name'">
                                  <div class="d-flex align-items-center">
                                    <div>
                                      <h6 class="m-0">{{ props.row.name }}</h6>
                                      <small class="text-secondary d-block">
                                        {{ props.row.no }} {{ props.row.liter ? `(${props.row.liter} liter)` : '' }}
                                      </small>
                                      <small
                                        v-if="props.row.agentItem1 || props.row.agentItem2"
                                        class="font-weight-bold"
                                      >
                                        {{ findSetLiter(props.row) }}
                                      </small>
                                    </div>
                                    <b-avatar
                                      v-if="props.row.agentItem1 || props.row.agentItem2"
                                      :id="`popover-${index}-${props.row.originalIndex}`"
                                      variant="light-primary"
                                      :size="28"
                                      class="ml-75"
                                    >
                                      <feather-icon
                                        icon="LinkIcon"
                                        size="16"
                                      />
                                    </b-avatar>
                                    <b-popover
                                      v-if="props.row.agentItem1 || props.row.agentItem2"
                                      :target="`popover-${index}-${props.row.originalIndex}`"
                                      triggers="hover focus"
                                      placement="topright"
                                      boundary="viewport"
                                    >
                                      <small class="text-primary">
                                        Berpasangan dengan agent item
                                      </small>
                                      <table
                                        border="0"
                                        class="mt-75"
                                        style="border-color: #dbdbdb"
                                      >
                                        <tr>
                                          <th class="pr-75">
                                            Item
                                          </th>
                                          <th>
                                            Vol.
                                          </th>
                                        </tr>
                                        <tr>
                                          <td class="py-50 pr-1">
                                            <small class="font-weight-bold d-block">
                                              {{ props.row.name }}
                                            </small>
                                            <small class="text-muted">
                                              {{ props.row.no }} ({{ props.row.liter }} liter)
                                            </small>
                                          </td>
                                          <td class="py-50">
                                            <h6 class="m-0">
                                              {{
                                                props.row[`volume${index}`] && props.row.agentItem1 && props.row.agentItem2
                                                  ? +props.row[`volume${index}`] - +props.row.agentItem1[`volume${index}`] - +props.row.agentItem2[`volume${index}`] + 'L'
                                                  : props.row[`volume${index}`] && props.row.agentItem1
                                                    ? +props.row[`volume${index}`] - +props.row.agentItem1[`volume${index}`] + 'L'
                                                    : props.row[`volume${index}`]
                                                      ? props.row[`volume${index}`] + 'L'
                                                      : '0L'
                                              }}
                                            </h6>
                                          </td>
                                        </tr>
                                        <tr
                                          v-if="props.row.agentItem1"
                                        >
                                          <td class="py-50 pr-1">
                                            <small class="font-weight-bold d-block">
                                              {{ props.row.agentItem1.name }}
                                            </small>
                                            <small class="text-muted">
                                              {{ props.row.agentItem1.no }} ({{ props.row.agentItem1.liter }} liter)
                                            </small>
                                          </td>
                                          <td class="py-50">
                                            <h6 class="m-0">
                                              {{ props.row.agentItem1[`volume${index}`] ? props.row.agentItem1[`volume${index}`] + 'L' : '0L' }}
                                            </h6>
                                          </td>
                                        </tr>
                                        <tr
                                          v-if="props.row.agentItem2"
                                        >
                                          <td class="py-50 pr-1">
                                            <small class="font-weight-bold d-block">
                                              {{ props.row.agentItem2.name }}
                                            </small>
                                            <small class="text-muted">
                                              {{ props.row.agentItem2.no }} ({{ props.row.agentItem2.liter }} liter)
                                            </small>
                                          </td>
                                          <td class="py-50">
                                            <h6 class="m-0">
                                              {{ props.row.agentItem2[`volume${index}`] ? props.row.agentItem2[`volume${index}`] + 'L' : '0L' }}
                                            </h6>
                                          </td>
                                        </tr>
                                      </table>
                                    </b-popover>
                                  </div>
                                </span>
                                <span v-else-if="props.column.field === 'color'">
                                  <div v-if="props.row.type.id == 1 && props.row.color">
                                    <h6 class="m-0">{{ props.row.color.eng_name }}</h6>
                                    <small>{{ props.row.color.id_ral }}</small>
                                    <div
                                      v-if="props.row.color.hex_code"
                                      v-b-popover.hover.top="`#${props.row.color.hex_code}`"
                                      class="mt-50"
                                      :style="`height: 6px; width: 40px; border-radius: 2px; background: #${props.row.color.hex_code}`"
                                    />
                                  </div>
                                </span>
                                <span v-else-if="props.column.field === 'thinner'">
                                  <div v-if="props.row.type.id == 1 && props.row.thinner">
                                    <h6 class="m-0">{{ props.row.thinner.name }}</h6>
                                    <small>{{ props.row.thinner.no }}</small>
                                  </div>
                                </span>
                                <span v-else-if="props.column.field === 'volume'">
                                  <validation-provider
                                    #default="{ errors }"
                                    :name="`Volume ${index + 1} - ${props.row.originalIndex}`"
                                    :rules="`min_value:0|max_value:9999999|required|twodecimal|validatevol:${area.luasArea},${findSetLiter(props.row, true)},${+props.row[`volume${index}`]},${area.id}`"
                                  >
                                    <b-form-input
                                      v-model="selectedArea[index].selectedItem[props.row.originalIndex][`volume${index}`]"
                                      type="number"
                                      placeholder="Liter"
                                      @input="volumeChange($event, index, props.row.originalIndex, true)"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </span>
                                <span v-else-if="props.column.field === 'price'">
                                  <validation-provider
                                    #default="{ errors }"
                                    :name="`Harga ${index + 1} - ${props.row.originalIndex}`"
                                    rules="min_value:10000|max_value:9999999|required|twodecimal"
                                  >
                                    <b-input-group
                                      prepend="Rp."
                                      class="input-group-merge"
                                    >
                                      <b-form-input
                                        v-model="selectedArea[index].selectedItem[props.row.originalIndex][`price${index}`]"
                                        type="number"
                                        placeholder="Harga"
                                        @input="getTotal(index, props.row.originalIndex)"
                                      />
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                  <div v-if="permission.viewHpp">
                                    <label class="mt-50 text-secondary">HPP : </label>
                                    <h6 class="m-0">
                                      {{ formatCurrency(selectedArea[index].selectedItem[props.row.originalIndex][`hpp${index}`]) }}
                                    </h6>
                                  </div>
                                </span>
                                <span v-else-if="props.column.field === 'total'">
                                  <h6 class="m-0">{{ formatCurrency(selectedArea[index].selectedItem[props.row.originalIndex][`total${index}`]) }}</h6>
                                  <small v-if="permission.viewHpp">{{ formatCurrency(selectedArea[index].selectedItem[props.row.originalIndex][`totalhpp${index}`]) }}<span class="text-muted ml-25">(HPP)</span></small>
                                </span>
                              </template>
                            </vue-good-table>
                          </b-overlay>
                        </b-card>
                      </div>
                    </b-collapse>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </XyzTransition>
          </b-col>
          <b-col
            cols="12"
            xl="4"
          >
            <XyzTransition
              appear
              xyz="fade right-3 delay-5"
            >
              <b-card v-if="selectedArea.length && selectedArea[0].selectedItem && selectedArea[0].selectedItem.length">
                <div class="d-flex justify-content-between align-items-start">
                  <h4>Summary</h4>
                  <b-badge variant="info">
                    Versi : {{ reviseMode || editMode ? version : '1' }}
                  </b-badge>
                </div>
                <div class="d-flex">
                  <div class="d-flex flex-wrap">
                    <div class="mr-2">
                      <small class="text-muted">Berlaku dari</small>
                      <h6 class="m-0 mt-25 mb-50">
                        {{ formatDate(quotationData.issuedDate) }}
                      </h6>
                    </div>
                    <div>
                      <small class="text-muted">Sampai</small>
                      <h6 class="m-0 mt-25">
                        {{ formatDate(quotationData.dueDate) }}
                      </h6>
                    </div>
                  </div>
                  <b-badge
                    class="ml-auto align-self-center"
                    variant="warning"
                  >
                    Draft
                  </b-badge>
                </div>
                <div
                  v-if="quotationData.customer.id"
                  class="d-flex align-items-center mt-1 mb-1"
                >
                  <b-avatar
                    size="4rem"
                    :text="avatarText(quotationData.customer.name)"
                    variant="secondary"
                    class="mr-1"
                  />
                  <div class="flex-fill">
                    <router-link :to="{ name: 'customer-detail', params: {id: quotationData.customer.id.toString()} }">
                      <h6 class="font-weight-bolder m-0">
                        {{ quotationData.customer.name }}
                      </h6>
                    </router-link>
                    <small class="text-muted d-block">
                      {{ quotationData.customer.customerNo }}
                    </small>
                    <small
                      v-if="quotationData.customer.category && quotationData.customer.priceCategory"
                      class="text-secondary"
                    >
                      {{ quotationData.customer.category.name }} - {{ quotationData.customer.priceCategory.name }}
                    </small>
                    <div v-if="quotationData.customer.customerLimitAmountValue">
                      <div class="d-flex justify-content-between">
                        <small
                          v-b-popover.hover.top="`Sisa piutang : ${formatCurrency(quotationData.customer.balance)}`"
                          class="m-0"
                        >
                          {{ shortNumber(quotationData.customer.balance) }}
                        </small>
                        <small
                          v-b-popover.hover.top="`Limit piutang : ${formatCurrency(quotationData.customer.customerLimitAmountValue)}`"
                          class="m-0"
                        >
                          {{ shortNumber(quotationData.customer.customerLimitAmountValue) }}
                        </small>
                      </div>
                      <b-progress
                        class="mt-50"
                        :value="quotationData.customer.balance"
                        :max="quotationData.customer.customerLimitAmountValue"
                      />
                    </div>
                    <div v-else>
                      <div class="d-flex justify-content-between">
                        <small>Balance :</small>
                        <small>{{ shortNumber(quotationData.customer.balance) }}</small>
                      </div>
                      <b-progress
                        v-b-popover.hover.top="'Customer ini tidak punya limit, sehingga Sisa piutang tidak terbatas.'"
                        class="mt-50"
                        :value="100"
                        :max="100"
                      />
                    </div>
                    <!-- <div v-if="quotationData.customer.customerLimitAmountValue">
                      <div class="d-flex justify-content-between">
                        <small
                          v-b-popover.hover.top="`Penggunaan piutang : ${formatCurrency(quotationData.customer.balance)}`"
                          class="m-0"
                        >
                          {{ shortNumber(quotationData.customer.balance) }}
                        </small>
                        <small
                          v-b-popover.hover.top="`Limit piutang : ${formatCurrency(quotationData.customer.customerLimitAmountValue)}`"
                          class="m-0"
                        >
                          {{ shortNumber(quotationData.customer.customerLimitAmountValue) }}
                        </small>
                      </div>
                      <b-progress
                        class="mt-50"
                        :value="quotationData.customer.balance"
                        :max="quotationData.customer.customerLimitAmountValue"
                      />
                    </div> -->
                  </div>
                </div>
                <small class="text-muted">Project</small>
                <h5 class="font-weight-bolder m-0 mb-50">
                  {{ quotationData.project.name }}
                </h5>
                <small class="text-muted">Area</small>
                <div
                  v-for="(area, index) in selectedArea"
                  :key="index"
                >
                  <div class="d-flex justify-content-between">
                    <h6 class="text-secondary">
                      {{ area.name }} <span class="text-muted">({{ area.selectedItem.length }} item)</span>
                    </h6>
                    <h6>{{ formatCurrency(area.total) }}</h6>
                  </div>
                </div>
                <hr>
                <div class="d-flex justify-content-between align-items-center mt-1">
                  <small class="text-muted">Subtotal</small>
                  <h6>{{ formatCurrency(quotationData.subtotal) }}</h6>
                </div>
                <b-collapse
                  :visible="quotationData.subtotal > 0"
                >
                  <div class="mt-1 mb-1">
                    <small class="text-muted mt-1">Diskon</small>
                    <validation-provider
                      #default="{ errors }"
                      name="Diskon"
                      :rules="selectedDiscount === '%'
                        ? 'positive|min_value:0|max_value:100|twodecimal'
                        : `positive|min_value:0|max_value:${quotationData.subtotal}|integer`"
                    >
                      <b-input-group class="mt-50">
                        <b-input-group-prepend>
                          <b-dropdown
                            :text="selectedDiscount"
                            variant="outline-secondary"
                          >
                            <b-dropdown-item @click="changeDiscountMode('%')">
                              %
                            </b-dropdown-item>
                            <b-dropdown-item @click="changeDiscountMode('Rp')">
                              Rp
                            </b-dropdown-item>
                          </b-dropdown>
                        </b-input-group-prepend>
                        <b-form-input
                          v-if="selectedDiscount === '%'"
                          v-model="discount"
                          type="number"
                          :placeholder="selectedDiscount === '%' ? '100' : '1000000'"
                          min="0"
                          max="100"
                          @input="getSubtotal"
                        />
                        <b-form-input
                          v-else
                          v-model="discount"
                          type="number"
                          :placeholder="selectedDiscount === '%' ? '100' : '1000000'"
                          min="0"
                          @input="getSubtotal"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                  <div class="d-flex justify-content-between mt-1 align-items-center">
                    <small class="font-weight-bolder">Diskon</small>
                    <h5 class="font-weight-bolder">
                      {{ formatCurrency(quotationData.diskon) }}
                    </h5>
                  </div>
                </b-collapse>
                <div
                  v-if="permission.viewHpp"
                  class="d-flex justify-content-between mt-1 align-items-center"
                >
                  <small class="font-weight-bolder">Total HPP</small>
                  <h5 class="font-weight-bolder">
                    {{ formatCurrency(quotationData.totalhpp) }}
                  </h5>
                </div>
                <div class="d-flex justify-content-between mt-1 align-items-center">
                  <small class="font-weight-bolder">Total</small>
                  <h5 class="font-weight-bolder">
                    {{ formatCurrency(quotationData.total) }}
                  </h5>
                </div>
                <hr>
                <b-overlay
                  :show="loadingAdd"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    block
                    variant="primary"
                    @click="submitQuote({ draft: false })"
                  >
                    {{ editMode ? 'Update Quote' : 'Submit' }}
                  </b-button>
                  <b-button
                    v-if="!editMode"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    block
                    variant="outline-primary"
                    @click="submitQuote({ draft: true })"
                  >
                    Draft
                  </b-button>
                </b-overlay>
              </b-card>
            </XyzTransition>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <error-endpoint
      v-else
      :key="error"
      ref="error"
      :error="error"
    />

    <pdf-preview
      ref="pdf"
    />
  </div>
</template>

<script>
import {
  BForm, BCard, BCardBody, BRow, BCol, BInputGroup, BInputGroupPrepend, BFormInput,
  BFormGroup, BCollapse, BSpinner, BButton, BBadge, BAvatar, BDropdown, BDropdownItem,
  BOverlay, BFormTextarea, BSkeleton, BImg, BProgress, VBPopover, BPopover, BInputGroupAppend,
} from 'bootstrap-vue'
import BackNavigation from '@/components/misc/BackNavigation.vue'
import flatPickr from 'vue-flatpickr-component'
import CustomerAutoSuggest from '@/components/input/CustomerAutoSuggest.vue'
import ProjectAutoSuggest from '@/components/input/ProjectAutoSuggest.vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import SchemeItemAutoSuggest from '@/components/input/SchemeItemAutoSuggest.vue'
import ItemAutoSuggest from '@/components/input/ItemAutoSuggest.vue'
import { VueGoodTable } from 'vue-good-table'
import { createHelpers } from 'vuex-map-fields'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import { avatarText, formatCurrency, shortNumber } from '@core/utils/filter'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, min, max, positive, integer, minValue, maxValue,
} from '@validations'
import ErrorEndpoint from '@/components/misc/ErrorEndpoint.vue'
import PdfPreview from '@/components/misc/PdfPreview.vue'
import { surfacePreparations } from '@/graphql/query'
import errorModal from '@/store/error-handling'
import { apolloClient } from '@/vue-apollo'
import gql from 'graphql-tag'

const { mapFields } = createHelpers({
  getterType: 'getQuotesFormField',
  mutationType: 'updateQuotesFormField',
})

export default {
  components: {
    BForm,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormInput,
    BFormGroup,
    BCollapse,
    BSpinner,
    BButton,
    BBadge,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BFormTextarea,
    BSkeleton,
    BImg,
    BProgress,
    BPopover,
    BackNavigation,
    flatPickr,
    CustomerAutoSuggest,
    ProjectAutoSuggest,
    SchemeItemAutoSuggest,
    ItemAutoSuggest,
    vSelect,
    VueGoodTable,
    ValidationProvider,
    ValidationObserver,
    ErrorEndpoint,
    PdfPreview,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    selectedCustomer: {
      type: Object,
      default: () => {},
    },
    selectedProject: {
      type: Object,
      default: () => {},
    },
    quoteData: {
      type: Object,
      default: () => {},
    },
    id: {
      type: String,
      default: '',
    },
    version: {
      type: String,
      default: '',
    },
    resetFormState: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      minValue,
      maxValue,
      required,
      min,
      max,
      positive,
      integer,
      avatarText,
      shortNumber,
      formatCurrency,
      itemTableColumn: [
        {
          label: '',
          field: 'sort',
          type: 'number',
          sortable: false,
          hidden: true,
        },
        {
          label: 'Item',
          field: 'name',
          width: '240px',
          sortable: false,
        },
        {
          label: 'Warna',
          field: 'color',
          width: '140px',
          sortable: false,
        },
        {
          label: 'VS',
          field: 'vs_volume_solid',
          sortable: false,
        },
        {
          label: 'Coat',
          field: 'coat',
          width: '110px',
          sortable: false,
        },
        {
          label: 'DFT',
          field: 'dft',
          width: '120px',
          sortable: false,
        },
        {
          label: 'Loss',
          field: 'loss',
          width: '120px',
          sortable: false,
        },
        {
          label: 'WFT',
          field: 'wft',
          sortable: false,
        },
        {
          label: 'Theory',
          field: 'theory',
          sortable: false,
        },
        {
          label: 'Practical',
          field: 'practical',
          sortable: false,
        },
        {
          label: 'Thinner',
          field: 'thinner',
          sortable: false,
        },
        {
          label: 'Volume',
          field: 'volume',
          width: '110px',
          sortable: false,
        },
        {
          label: 'Harga',
          field: 'price',
          sortable: false,
          width: '180px',
        },
        {
          label: 'Total',
          field: 'total',
          sortable: false,
        },
        {
          label: '',
          field: 'action',
          tdClass: 'text-right',
          sortable: false,
          width: '120px',
        },
      ],
      freeItemTableColumn: [
        {
          label: '',
          field: 'sort',
          type: 'number',
          sortable: false,
          hidden: true,
        },
        {
          label: 'Item',
          field: 'name',
          width: '240px',
          sortable: false,
        },
        {
          label: 'Warna',
          field: 'color',
          width: '140px',
          sortable: false,
        },
        {
          label: 'Thinner',
          field: 'thinner',
          sortable: false,
        },
        {
          label: 'Volume',
          field: 'volume',
          width: '140px',
          sortable: false,
        },
        {
          label: 'Harga',
          field: 'price',
          sortable: false,
          width: '180px',
        },
        {
          label: 'Total',
          field: 'total',
          sortable: false,
          width: '180px',
        },
        {
          label: '',
          field: 'action',
          tdClass: 'text-right',
          sortable: false,
          width: '120px',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      permission: 'getUserPermission',
      selecteddb: 'getSelectedDB',
    }),
    ...mapFields([
      'loading',
      'loadingAdd',
      'selectedDiscount',
      'discount',
      'quotationData',
      'loadingAreaOptions',
      'areaOptions',
      'selectedArea',
      'selectedAreaVModel',
      'surfaceOptions',
      'prevQuote',
      'error',
    ]),
    reviseMode() {
      return this.id && this.version && this.$route.name === 'revisequote'
    },
    editMode() {
      return this.id && this.version && this.$route.name === 'editquote'
    },
    dirtyForm() {
      if (this.quotationData.notes) return true
      if (this.quotationData.customer.name) return true
      if (this.selectedArea.length) return true
      return false
    },
  },
  mounted() {
    console.log(this.selectedCustomer, this.selectedProject)
    if (this.resetFormState) {
      this.resetState()
    }

    if (this.selectedCustomer) {
      this.quotationData.customer = this.selectedCustomer
    }

    if (this.selectedProject) {
      apolloClient.query({
        query: gql`
            query{customerDetail(customer_id: "${this.selectedProject.customer_id ? this.selectedProject.customer_id : this.selectedProject.customer.id}"){
              id
              name
              customerNo
              mobilePhone
              workPhone
              npwpNo
              email
              billStreet
              billCity
              billProvince
              billCountry
              customerLimitAmountValue
              defaultSalesDisc
              saldoPiutang
              priceCategory {
                id
                name
              }
              category {
                id
                name
              }
              isAssigned
              term {
                id
                name
              }
            }}
          `,
        context: {
          headers: {
            Authorization: this.$store.getters.getBearerToken,
          },
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        this.quotationData.customer = result.data.customerDetail
        this.quotationData.project = this.selectedProject
        this.getAreaOptions()
      }).catch(err => {
        errorModal(err)
      })
    }

    this.$apollo.query({
      query: surfacePreparations,
      context: {
        headers: {
          Authorization: this.$store.getters.getBearerToken,
        },
      },
      fetchPolicy: 'no-cache',
    }).then(result => {
      this.surfaceOptions = result.data.surfacePreparations
    })

    if (this.reviseMode || this.editMode) {
      this.initForm()
    }
  },
  methods: {
    async initForm() {
      this.loading = true
      await this.$store.dispatch('getQuoteDetail', { quote_id: this.id, version: this.version }).then(prev => {
        if (prev.status.id === 1 || prev.status.id === 4 || prev.status.id === 5) {
          this.prevQuote = prev

          this.selectedDiscount = 'Rp'
          this.discount = this.prevQuote.discount ? this.prevQuote.discount : 0

          this.quotationData = {
            id: this.prevQuote.id,
            issuedDate: new Date(Date.parse(this.prevQuote.created_at)),
            dueDate: new Date(Date.parse(this.prevQuote.valid_until)),
            customer: this.prevQuote.project.customer,
            project: this.prevQuote.project,
            subtotal: 0,
            subtotalhpp: 0,
            total: this.prevQuote.total,
            totalhpp: 0,
            diskon: this.prevQuote.discount ? this.prevQuote.discount : 0,
            notes: this.prevQuote.notes,
          }

          this.loadingAreaOptions = true
          this.getAreaOptions()
          this.selectedArea = this.prevQuote.areaItems.map(area => ({
            id: area.category.id,
            name: area.category.name,
            selectedSurface: area.surface_preparation
              ? area.surface_preparation.split(', ').map(surface => this.surfaceOptions.filter(opt => surface === opt.name)[0])
              : [],
            selectedItem: [],
            notes: area.notes,
            showNotes: area.notes != null,
            luasArea: area.surface_area,
            total: area.total,
            totalhpp: area.total_hpp,
            totalVol: 0,
            totalDFT: 0,
            loadingArea: false,
            loss: area.items[0].loss,
          }))

          this.selectedArea.forEach(async (area, index) => {
            this.selectedArea[index].loadingArea = true
            this.prevQuote.areaItems[index].items.forEach((data, indexItem) => {
              const itemData = {
                ...data,
              }

              itemData.id = data.item_id
              itemData.name = data.item_name
              itemData.type = data.item_type
              itemData.color = data.color
              itemData.liter = data.packaging.liter
              itemData.no = data.item_code
              itemData.vs_volume_solid = data.vs_volume_solid
              itemData[`coat${index}`] = data.coat
              itemData[`dft${index}`] = data.dry_film_thickness
              itemData[`loss${index}`] = data.loss
              itemData[`wft${index}`] = 0
              itemData[`theory${index}`] = data.theoritical
              itemData[`practical${index}`] = data.practical
              itemData[`volume${index}`] = data.liter
              itemData[`realVolume${index}`] = 0
              itemData[`total${index}`] = data.subtotal
              itemData[`totalhpp${index}`] = data.subtotalhpp
              itemData[`price${index}`] = data.price ?? 0
              itemData[`hpp${index}`] = data.hpp ?? 0
              itemData.sort = data.item_type.id === 3 ? 99 : indexItem
              itemData.lapis_uid = data.lapis_uid
              itemData.mainRatio = {}

              if (itemData.agent_item) {
                itemData.agentItem1 = {
                  name: itemData.agent_item.name,
                  liter: itemData.agent_item.packaging && itemData.agent_item.packaging.liter ? itemData.agent_item.packaging.liter : 0,
                  no: itemData.agent_item.no,
                }

                itemData.agentItem1[`volume${index}`] = itemData.liter_detail.agent_item
              }

              if (itemData.agent_item_2) {
                itemData.agentItem2 = {
                  name: itemData.agent_item_2.name,
                  liter: itemData.agent_item_2.packaging && itemData.agent_item_2.packaging.liter ? itemData.agent_item_2.packaging.liter : 0,
                  no: itemData.agent_item_2.no,
                }

                itemData.agentItem2[`volume${index}`] = itemData.liter_detail.agent_item_2
              }

              if (data.recommended_thinner_id) {
                const thinner = this.prevQuote.areaItems[index].items.filter(el => el.item_id === data.recommended_thinner_id)
                itemData.thinner = thinner.length ? {
                  no: thinner[0].item_code,
                  id: thinner[0].item_id,
                  name: thinner[0].item_name,
                  mainRatio: {},
                } : null
              }

              this.selectedArea[index].selectedItem.push(itemData)
              this.getWft(index, indexItem)
              this.getTotal(index, indexItem)
            })
            this.getTotalVol(index)
            this.changeStuff()
            this.selectedArea[index].loadingArea = false
          })

          this.loading = false
        } else {
          this.loading = 'error'
          this.error = 'Quote ini tidak bisa diubah / direvisi.'
        }
      }).catch(error => {
        this.loading = 'error'
        this.error = `${error.graphQLErrors[0].message}`
      })
    },
    changeStuff(fromItsOwnInput) {
      this.selectedArea.forEach((area, indexArea) => {
        if (area.selectedItem.length > 0) {
          area.selectedItem.forEach((item, indexItem) => {
            this.getWft(indexArea, indexItem)
            this.getTheory(indexArea, indexItem)
            this.getPractical(indexArea, indexItem)

            if (fromItsOwnInput && this.selectedArea[indexArea].selectedItem[indexItem][`totalChanged${indexArea}`]) {
              this.selectedArea[indexArea].selectedItem[indexItem][`totalChanged${indexArea}`] = false
            } else {
              this.getVolume(indexArea, indexItem)
            }
          })
        }
      })
    },
    formatDate(date) {
      if (!date) {
        return ''
      }
      const parsedDate = Date.parse(date)
      return moment(parsedDate).format('DD-MM-YYYY')
    },
    ...mapActions(['getAreaCategory', 'getSchemeDetail', 'getItemsDetailCustomer']),
    handleCustomerSelected(data) {
      if (this.quotationData.customer.id && this.quotationData.customer.id !== data.id) {
        // this.$swal({
        //   title: 'Ubah customer?',
        //   text: 'Progres input area akan hilang dan tidak dapat dikembalikan',
        //   showCancelButton: true,
        //   confirmButtonText: 'Yakin',
        //   cancelButtonText: 'Batalkan',
        //   customClass: {
        //     confirmButton: 'btn btn-danger',
        //     cancelButton: ' text-danger btn btn-outline-danger ml-1',
        //   },
        //   buttonsStyling: false,
        // }).then(result => {
        //   if (result.value) {
        this.$store.commit('setSelectedCustomer', data.id)
        this.quotationData.customer = data
        this.discount = data.defaultSalesDisc ? data.defaultSalesDisc : 0
        // this.resetArea()
        // }
        // })
      } else {
        this.$store.commit('setSelectedCustomer', data.id)
        this.quotationData.customer = data
        this.discount = data.defaultSalesDisc ? data.defaultSalesDisc : 0
      }
    },
    handleProjectSelected(data) {
      if (this.quotationData.project.category && data.category.id !== this.quotationData.project.category.id) {
        this.$swal({
          title: 'Ubah project?',
          text: 'Progres input area akan hilang dan tidak dapat dikembalikan',
          showCancelButton: true,
          confirmButtonText: 'Yakin',
          cancelButtonText: 'Batalkan',
          customClass: {
            confirmButton: 'btn btn-danger',
            cancelButton: ' text-danger btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            // if (data.category.id !== this.quotationData.project.category.id) {
            this.selectedArea = []
            this.getAreaOptions(true)
            this.selectedAreaVModel = []
            // }

            this.quotationData.project = data
          }
        })
      } else {
        this.quotationData.project = data
        this.getAreaOptions()
      }
    },
    getAreaOptions(isReset = false) {
      this.loadingAreaOptions = true
      this.$store.dispatch('getProjectDetail', this.quotationData.project.id).then(projectDetail => {
        this.areaOptions = projectDetail.projectDetail.surfaceAreas.map(el => ({
          id: el.area_category.id,
          name: el.area_category.name,
          surface_area: el.surface_area,
        }))
        if (isReset) {
          this.getAreaCategory(this.quotationData.project.category.id).then(category => {
            this.areaOptions = []
            this.areaOptions.push(...category.areaCategory.filter(el => !this.areaOptions.some(area => area.id === el.id)))
            this.loadingAreaOptions = false
          })
        } else {
          this.getAreaCategory(this.quotationData.project.category.id).then(category => {
            this.areaOptions.push(...category.areaCategory.filter(el => !this.areaOptions.some(area => area.id === el.id)))
            this.loadingAreaOptions = false
          })
        }

        if (this.prevQuote && !isReset) {
          this.selectedAreaVModel = this.areaOptions.filter(el => this.prevQuote.areaItems.some(area => area.category.id === el.id))
        }
      })
    },
    handleSelectedArea(item) {
      if (this.selectedArea.length > 0) {
        item.forEach(el => {
          if (!this.selectedArea.some(area => area.id === el.id)) {
            this.selectedArea.push({
              id: el.id,
              name: el.name,
              selectedSurface: [],
              selectedItem: [],
              notes: '',
              showNotes: false,
              luasArea: el.surface_area ? el.surface_area : 0,
              total: 0,
              totalhpp: 0,
              totalDFT: 0,
              totalVol: 0,
              loadingArea: false,
              loss: 0,
            })
          }
        })
      } else {
        item.forEach(el => {
          this.selectedArea.push({
            id: el.id,
            name: el.name,
            selectedSurface: [],
            selectedItem: [],
            notes: '',
            showNotes: false,
            luasArea: el.surface_area ? el.surface_area : 0,
            total: 0,
            totalhpp: 0,
            totalDFT: 0,
            totalVol: 0,
            loadingArea: false,
            loss: 0,
          })
        })
      }
    },
    handleDeselectedArea(item) {
      if (item) {
        this.$swal({
          title: 'Hapus area?',
          text: 'Progres input area akan hilang dan tidak dapat dikembalikan',
          showCancelButton: true,
          confirmButtonText: 'Yakin',
          cancelButtonText: 'Batalkan',
          customClass: {
            confirmButton: 'btn btn-danger',
            cancelButton: ' text-danger btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.selectedAreaVModel = this.selectedAreaVModel.filter(el => el.id !== item.id)
            this.selectedArea = this.selectedArea.filter(el => el.id !== item.id)
            this.getSubtotal()
            this.getAllVol()
            this.triggerFreeItemChange()
          } else {
            /* eslint-disable no-lonely-if */
            if (!this.selectedAreaVModel.some(el => el.id === item.id)) {
              this.selectedAreaVModel.push(item)
            }
          }
        })
      }
    },
    async handleSelectedItem(data, index) {
      this.selectedArea[index].loadingArea = true
      const customerId = this.quotationData.customer.id
      if (data.name === 'scheme') {
        await this.getSchemeDetail(Number(data.item.id)).then(async schemeDetail => {
          const itemScheme = []
          this.selectedArea[index].loss = schemeDetail.items.filter(el => el.loss !== null)[0]?.loss ?? 0
          await Promise.all(schemeDetail.items.map(async (itemDetail, indexItem) => {
            if (itemDetail.type.id === 1) {
              const uid = this.generateRandomHex()
              const itemData = itemDetail

              itemData[`coat${index}`] = itemDetail.coat
              itemData[`dft${index}`] = itemDetail.dry_film_thickness
              itemData[`loss${index}`] = itemDetail.loss
              itemData[`wft${index}`] = ''
              itemData[`theory${index}`] = ''
              itemData[`practical${index}`] = ''
              itemData[`volume${index}`] = ''
              itemData[`realVolume${index}`] = 0
              itemData[`total${index}`] = ''
              itemData[`totalhpp${index}`] = ''
              itemData[`price${index}`] = itemDetail.detailSellingPrice.filter(price => price.priceCategory.name === this.quotationData.customer.priceCategory)[0]?.price ?? 0
              itemData[`hpp${index}`] = itemDetail?.hpp ?? 0
              itemData.sort = indexItem + 1

              if (itemDetail.agent_item_id) {
                await this.getItemsDetailCustomer({ item_id: +itemDetail.agent_item_id, customer_id: customerId }).then(agent => {
                  const agentItem1 = agent
                  agentItem1[`volume${index}`] = ''
                  agentItem1[`price${index}`] = agent.detailSellingPrice[0]?.price ?? 0
                  agentItem1[`hpp${index}`] = agent?.hpp ?? 0
                  agentItem1.sort = 98
                  agentItem1.mainRatio = {}
                  itemData.lapis_uid = uid

                  itemData.agentItem1 = agentItem1
                })
              }

              if (itemDetail.agent_item_2_id) {
                await this.getItemsDetailCustomer({ item_id: +itemDetail.agent_item_2_id, customer_id: customerId }).then(agent => {
                  const agentItem2 = agent
                  agentItem2[`volume${index}`] = ''
                  agentItem2[`price${index}`] = agent.detailSellingPrice[0]?.price ?? 0
                  agentItem2[`hpp${index}`] = agent?.hpp ?? 0
                  agentItem2.sort = 98
                  agentItem2.mainRatio = {}
                  itemData.lapis_uid = uid

                  itemData.agentItem2 = agentItem2
                })
              }

              if (itemDetail.recommended_thinner_id) {
                await this.getItemsDetailCustomer({ item_id: Number(itemDetail.recommended_thinner_id), customer_id: customerId }).then(thinner => {
                  itemData.thinner = thinner
                  const thinnerItem = thinner
                  thinnerItem.sort = 99
                  thinnerItem[`volume${index}`] = ''
                  thinnerItem[`price${index}`] = thinner.detailSellingPrice[0]?.price ?? 0
                  thinnerItem[`hpp${index}`] = thinner?.hpp ?? 0
                  thinnerItem.mainRatio = {}

                  if (!this.selectedArea[index].selectedItem.some(item => item.id === +itemDetail.recommended_thinner_id)) {
                    this.selectedArea[index].selectedItem.push(thinnerItem)
                  }
                })
              }

              itemScheme[indexItem] = itemData
            }
          }))

          this.selectedArea[index].selectedItem.push(...itemScheme)
          setTimeout(() => {
            this.changeStuff()
            this.selectedArea[index].loadingArea = false
          }, 500)
        }).catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Salah satu atau lebih item tidak dapat dikembalikan, harap isi kembali.',
              icon: 'EditIcon',
              variant: 'warning',
            },
          })
          this.selectedArea[index].loadingArea = false
        })
      } else {
        await this.getItemsDetailCustomer({ item_id: Number(data.item.id), customer_id: customerId }).then(async itemDetail => {
          const uid = this.generateRandomHex()
          const itemData = itemDetail
          let agentItem1 = {}
          let agentItem2 = {}
          let thinnerItem = {}

          itemData[`coat${index}`] = ''
          itemData[`dft${index}`] = ''
          itemData[`loss${index}`] = this.selectedArea[index].loss ? this.selectedArea[index].loss : '0'
          itemData[`wft${index}`] = ''
          itemData[`theory${index}`] = ''
          itemData[`practical${index}`] = ''
          itemData[`volume${index}`] = ''
          itemData[`realVolume${index}`] = 0
          itemData[`total${index}`] = ''
          itemData[`totalhpp${index}`] = ''
          itemData[`price${index}`] = itemDetail.detailSellingPrice[0]?.price ?? 0
          itemData[`hpp${index}`] = itemDetail?.hpp ?? 0
          itemData.sort = this.selectedArea[index].selectedItem.length + 1

          if (itemDetail.agent_item_id) {
            await this.getItemsDetailCustomer({ item_id: Number(itemDetail.agent_item_id), customer_id: customerId }).then(agent => {
              agentItem1 = agent
              agentItem1[`volume${index}`] = ''
              agentItem1[`price${index}`] = agent.detailSellingPrice[0]?.price ?? 0
              agentItem1[`hpp${index}`] = agent?.hpp ?? 0
              agentItem1.sort = 98
              agentItem1.mainRatio = {}
              agentItem1.lapis_uid = uid
              itemData.lapis_uid = uid

              itemData.agentItem1 = agentItem1
            })
          }

          if (itemDetail.agent_item_2_id) {
            await this.getItemsDetailCustomer({ item_id: +itemDetail.agent_item_2_id, customer_id: customerId }).then(agent => {
              agentItem2 = agent
              agentItem2[`volume${index}`] = ''
              agentItem2[`price${index}`] = agent.detailSellingPrice[0]?.price ?? 0
              agentItem2[`hpp${index}`] = agent?.hpp ?? 0
              agentItem2.sort = 98
              agentItem2.mainRatio = {}
              agentItem2.lapis_uid = uid
              itemData.lapis_uid = uid

              itemData.agentItem2 = agentItem2
            })
          }

          if (itemDetail.recommended_thinner_id) {
            await this.getItemsDetailCustomer({ item_id: Number(itemDetail.recommended_thinner_id), customer_id: customerId }).then(thinner => {
              itemData.thinner = thinner
              thinnerItem = thinner
              thinnerItem.sort = 99
              thinnerItem[`volume${index}`] = ''
              thinnerItem[`price${index}`] = thinner.detailSellingPrice[0]?.price ?? 0
              thinnerItem[`hpp${index}`] = thinner?.hpp ?? 0
              thinnerItem.mainRatio = {}

              if (!this.selectedArea[index].selectedItem.some(item => item.id === thinnerItem.id)) {
                this.selectedArea[index].selectedItem.push(thinnerItem)
              }
            })
          }

          this.selectedArea[index].selectedItem.push(itemData)
        }).catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Salah satu atau lebih item tidak dapat dikembalikan, harap isi kembali.',
              icon: 'EditIcon',
              variant: 'warning',
            },
          })
          this.selectedArea[index].loadingArea = false
        })
      }
      this.triggerFreeItemChange()
      this.selectedArea[index].loadingArea = false
    },
    async handleSelectedFreeItem(data, index) {
      this.selectedArea[index].loadingArea = true
      await this.getItemsDetailCustomer({ item_id: Number(data.id), customer_id: this.quotationData.customer.id }).then(async itemDetail => {
        const uid = this.generateRandomHex()
        const itemData = itemDetail
        let agentItem1 = {}
        let agentItem2 = {}
        let thinnerItem = {}

        itemData[`coat${index}`] = ''
        itemData[`dft${index}`] = ''
        itemData[`loss${index}`] = '0'
        itemData[`wft${index}`] = ''
        itemData[`theory${index}`] = ''
        itemData[`practical${index}`] = ''
        itemData[`volume${index}`] = ''
        itemData[`realVolume${index}`] = 0
        itemData[`total${index}`] = ''
        itemData[`totalhpp${index}`] = ''
        itemData[`price${index}`] = itemDetail.detailSellingPrice[0]?.price ?? 0
        itemData[`hpp${index}`] = itemDetail?.hpp ?? 0
        itemData.sort = 1

        if (itemDetail.agent_item_id) {
          await this.getItemsDetailCustomer({ item_id: Number(itemDetail.agent_item_id), customer_id: this.quotationData.customer.id }).then(agent => {
            agentItem1 = agent
            agentItem1[`volume${index}`] = ''
            agentItem1[`price${index}`] = agent.detailSellingPrice[0]?.price ?? 0
            agentItem1[`hpp${index}`] = agent?.hpp ?? 0
            agentItem1.sort = 98
            agentItem1.mainRatio = {}
            agentItem1.lapis_uid = uid
            itemData.lapis_uid = uid

            itemData.agentItem1 = agentItem1
          })
        }

        if (itemDetail.agent_item_2_id) {
          await this.getItemsDetailCustomer({ item_id: Number(itemDetail.agent_item_2_id), customer_id: this.quotationData.customer.id }).then(agent => {
            agentItem2 = agent
            agentItem2[`volume${index}`] = ''
            agentItem2[`price${index}`] = agent.detailSellingPrice[0]?.price ?? 0
            agentItem2[`hpp${index}`] = agent?.hpp ?? 0
            agentItem2.sort = 98
            agentItem2.mainRatio = {}
            agentItem2.lapis_uid = uid
            itemData.lapis_uid = uid

            itemData.agentItem2 = agentItem2
          })
        }

        if (itemDetail.recommended_thinner_id) {
          await this.getItemsDetailCustomer({ item_id: Number(itemDetail.recommended_thinner_id), customer_id: this.quotationData.customer.id }).then(thinner => {
            itemData.thinner = thinner
            thinnerItem = thinner
            thinnerItem.sort = 99
            thinnerItem[`volume${index}`] = ''
            thinnerItem[`price${index}`] = thinner.detailSellingPrice[0]?.price ?? 0
            thinnerItem[`hpp${index}`] = thinner?.hpp ?? 0
            thinnerItem.mainRatio = {}

            if (this.selectedArea[index].name.toLowerCase() === 'anchor' && !this.selectedArea[index].selectedItem.some(item => item.id === thinner.id)) {
              this.selectedArea[index].selectedItem.push(thinnerItem)
            }
          })
        }

        if (this.selectedArea[index].name.toLowerCase() === 'free item') {
          if (!this.selectedArea[index].selectedItem.some(el => +el.id === +data.id)) {
            this.selectedArea[index].selectedItem.push(itemData)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Item tersebut sudah ada di table',
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
          }
        } else {
          this.selectedArea[index].selectedItem.push(itemData)
        }

        this.selectedArea[index].loadingArea = false
      })
    },
    deleteItem(index, item) {
      this.$swal({
        title: 'Hapus item?',
        text: 'Progres input akan hilang dan tidak dapat dikembalikan',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: ' text-danger btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          if (item.lapis_uid) {
            this.selectedArea[index].selectedItem = this.selectedArea[index].selectedItem.filter(el => {
              if (el.lapis_uid) {
                return el.id !== item.id || el.lapis_uid !== item.lapis_uid
              }

              return true
            })

            if (item.type.id === 1) {
              const indexAgent1 = this.selectedArea[index].selectedItem.findIndex(el => el.id === item.agent_item_id && el.lapis_uid === item.lapis_uid)
              const indexAgent2 = this.selectedArea[index].selectedItem.findIndex(el => el.id === item.agent_item_2_id && el.lapis_uid === item.lapis_uid)
              const indexThinner = this.selectedArea[index].selectedItem.findIndex(el => el.id === item.thinner.id)

              if (indexAgent1 !== -1) this.selectedArea[index].selectedItem[indexAgent1].lapis_uid = null
              if (indexAgent2 !== -1) this.selectedArea[index].selectedItem[indexAgent2].lapis_uid = null
              if (indexThinner !== -1) {
                this.selectedArea[index].selectedItem[indexThinner].mainRatio[item.lapis_uid] = 0
              }
            } else {
              const indexItem = this.selectedArea[index].selectedItem.findIndex(el => el.type.id === 1 && el.lapis_uid === item.lapis_uid)
              const indexAgent2 = this.selectedArea[index].selectedItem.findIndex(el => el.type.id === 2 && el.lapis_uid === item.lapis_uid)

              if (indexItem !== -1 && indexAgent2 === -1) {
                this.selectedArea[index].selectedItem[indexItem].lapis_uid = null
              }
            }
          } else {
            this.selectedArea[index].selectedItem = this.selectedArea[index].selectedItem.filter(el => el.id !== item.id)
          }

          this.getTotalHarga(index)
          this.changeStuff()
          this.triggerFreeItemChange()
        }
      })
    },
    generateRandomHex() {
      // eslint-disable-next-line no-bitwise
      return (Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0').toUpperCase()
    },
    resetArea() {
      this.quotationData.project = {}
      this.selectedArea = []
      this.selectedAreaVModel = []
    },
    getWft(indexArea, indexItem) {
      const dft = this.selectedArea[indexArea].selectedItem[indexItem][`dft${indexArea}`]
      const { vs_volume_solid } = this.selectedArea[indexArea].selectedItem[indexItem]

      if (Number(vs_volume_solid) && Number(dft)) {
        const wft = Number(dft) / Number(vs_volume_solid)
        this.selectedArea[indexArea].selectedItem[indexItem][`wft${indexArea}`] = Math.ceil(wft * 100) / 100

        return Math.ceil(wft * 100) / 100
      }

      return ''
    },
    getTheory(indexArea, indexItem) {
      const dft = this.selectedArea[indexArea].selectedItem[indexItem][`dft${indexArea}`]
      const { vs_volume_solid } = this.selectedArea[indexArea].selectedItem[indexItem]

      if (Number(vs_volume_solid) && Number(dft)) {
        const theory = (Number(vs_volume_solid) * 10) / Number(dft)

        this.selectedArea[indexArea].selectedItem[indexItem][`theory${indexArea}`] = Math.ceil(theory * 100) / 100

        return Math.ceil(theory * 100) / 100
      }

      return ''
    },
    getPractical(indexArea, indexItem) {
      const loss = this.selectedArea[indexArea].selectedItem[indexItem][`loss${indexArea}`]
      const theory = this.selectedArea[indexArea].selectedItem[indexItem][`theory${indexArea}`]

      if (Number(theory)) {
        const practical = Number(theory) * (1 - Number(loss) / 100)

        this.selectedArea[indexArea].selectedItem[indexItem][`practical${indexArea}`] = Math.ceil(practical * 100) / 100

        return Math.ceil(practical * 100) / 100
      }

      return ''
    },
    getVolume(indexArea, indexItem) {
      const {
        liter, agentItem1, agentItem2, recommended_thinner_id, ratio_recommended_thinner_id, lapis_uid,
      } = this.selectedArea[indexArea].selectedItem[indexItem]
      const practical = this.selectedArea[indexArea].selectedItem[indexItem][`practical${indexArea}`]
      const coat = this.selectedArea[indexArea].selectedItem[indexItem][`coat${indexArea}`]
      const { luasArea } = this.selectedArea[indexArea]
      const volume = ((Number(luasArea) / Number(practical))) * (coat / 100)
      let ratioVolume = 0
      /* eslint-disable camelcase */

      if (practical && liter && luasArea) {
        if (agentItem1 && agentItem2) {
          const ratioItem = liter
          const ratioAgent = this.selectedArea[indexArea].selectedItem[indexItem].agentItem1.liter
          const ratioAgent2 = this.selectedArea[indexArea].selectedItem[indexItem].agentItem2.liter
          const multiplier = ratioItem + ratioAgent + ratioAgent2
          const pembulatan = Math.ceil(volume / multiplier)
          const finalVolume = +luasArea > 1 ? (pembulatan * multiplier) : Math.round(volume.toPrecision(8) * 100) / 100
          const volumeItem = finalVolume * (ratioItem / multiplier)
          const volumeAgent = finalVolume * (ratioAgent / multiplier)
          const volumeAgent2 = finalVolume * (ratioAgent2 / multiplier)

          this.selectedArea[indexArea].selectedItem[indexItem][`volume${indexArea}`] = volumeItem
          this.selectedArea[indexArea].selectedItem[indexItem].agentItem1[`volume${indexArea}`] = Math.round(volumeAgent.toPrecision(8) * 100) / 100
          this.selectedArea[indexArea].selectedItem[indexItem].agentItem2[`volume${indexArea}`] = Math.round(volumeAgent2.toPrecision(8) * 100) / 100
        } else if (agentItem1 && !agentItem2) {
          const ratioItem = liter
          const ratioAgent = this.selectedArea[indexArea].selectedItem[indexItem].agentItem1.liter
          const multiplier = ratioItem + ratioAgent
          const pembulatan = Math.ceil(volume / multiplier)
          const finalVolume = +luasArea > 1 ? (pembulatan * multiplier) : Math.round(volume.toPrecision(8) * 100) / 100
          const volumeItem = finalVolume
          const volumeAgent = finalVolume * (ratioAgent / multiplier)

          this.selectedArea[indexArea].selectedItem[indexItem][`volume${indexArea}`] = volumeItem
          this.selectedArea[indexArea].selectedItem[indexItem].agentItem1[`volume${indexArea}`] = Math.round(volumeAgent.toPrecision(8) * 100) / 100
        } else {
          const pembulatan = Math.ceil(volume / liter)
          const finalVolume = +luasArea > 1 ? (pembulatan * liter) : Math.round(volume.toPrecision(8) * 100) / 100

          this.selectedArea[indexArea].selectedItem[indexItem][`volume${indexArea}`] = Math.round(finalVolume.toPrecision(12) * 100) / 100
        }

        ratioVolume = volume
        this.selectedArea[indexArea].selectedItem[indexItem][`realVolume${indexArea}`] = Math.round(volume.toPrecision(8) * 100) / 100

        if (recommended_thinner_id != null) {
          const thinnerItem = this.selectedArea[indexArea].selectedItem.filter(el => el.id === recommended_thinner_id)

          if (thinnerItem.length > 0) {
            const indexThinner = this.selectedArea[indexArea].selectedItem.indexOf(thinnerItem[0])

            const volumeThinner = ratioVolume / ratio_recommended_thinner_id

            this.selectedArea[indexArea].selectedItem[indexThinner].mainRatio[lapis_uid] = volumeThinner
            this.getAgentThinnerVolume(indexArea, indexThinner, +luasArea > 1)

            this.getTotal(indexArea, indexThinner)
          }
        }
      }

      this.getTotal(indexArea, indexItem)
      this.getTotalVol(indexArea)
    },
    getAgentThinnerVolume(indexArea, indexItem, rounding = true) {
      const { mainRatio, type, liter } = this.selectedArea[indexArea].selectedItem[indexItem]

      let totalVol = 0
      Object.keys(mainRatio).forEach(key => {
        totalVol += +mainRatio[key]
      })

      if (type.id === 3) {
        const ratioThinner = Math.ceil(totalVol / liter)
        this.selectedArea[indexArea].selectedItem[indexItem][`thinnerRealVolume${indexArea}`] = totalVol

        totalVol = rounding ? (ratioThinner * liter) : Math.round(totalVol * 100) / 100
      }

      this.selectedArea[indexArea].selectedItem[indexItem][`volume${indexArea}`] = totalVol
    },
    getTotal(indexArea, indexItem) {
      let total = 0
      let totalhpp = 0
      const hpp = this.selectedArea[indexArea].selectedItem[indexItem][`hpp${indexArea}`]
      const volume = this.selectedArea[indexArea].selectedItem[indexItem][`volume${indexArea}`]
      const price = this.selectedArea[indexArea].selectedItem[indexItem][`price${indexArea}`]

      if (price !== null && hpp !== null && volume) {
        total = parseFloat(price) * +volume
        totalhpp = parseFloat(hpp) * +volume

        if (this.selectedArea[indexArea].name.toUpperCase() === 'FREE ITEM') {
          total *= 0
        }

        this.selectedArea[indexArea].selectedItem[indexItem][`total${indexArea}`] = total
        this.selectedArea[indexArea].selectedItem[indexItem][`totalhpp${indexArea}`] = totalhpp

        this.getTotalHarga(indexArea)
      }

      return { total, totalhpp }
    },
    triggerFreeItemChange() {
      const indexArea = this.selectedArea.findIndex(el => el.name.toUpperCase() === 'FREE ITEM')

      if (indexArea > -1) {
        this.selectedArea[indexArea].selectedItem.forEach((item, indexItem) => {
          this.getTotal(indexArea, indexItem)
        })
      }
    },
    volumeChange(text, indexArea, indexItem, isFreeItem) {
      const { total, totalhpp } = this.getTotal(indexArea, indexItem)
      this.selectedArea[indexArea].selectedItem[indexItem][`totalChanged${indexArea}`] = true
      this.selectedArea[indexArea].selectedItem[indexItem][`total${indexArea}`] = total
      this.selectedArea[indexArea].selectedItem[indexItem][`totalhpp${indexArea}`] = totalhpp

      if (isFreeItem) {
        const volume = +text
        const {
          agent_item_id, agent_item_2_id, liter,
        } = this.selectedArea[indexArea].selectedItem[indexItem]
        const ratioItem = liter

        if (agent_item_id && agent_item_2_id) {
          const ratioAgent = this.selectedArea[indexArea].selectedItem[indexItem].agentItem1.liter
          const ratioAgent2 = this.selectedArea[indexArea].selectedItem[indexItem].agentItem2.liter
          const multiplier = ratioItem + ratioAgent + ratioAgent2
          const volumeAgent = volume * (ratioAgent / multiplier)
          const volumeAgent2 = volume * (ratioAgent2 / multiplier)

          this.selectedArea[indexArea].selectedItem[indexItem].agentItem1[`volume${indexArea}`] = Math.round(volumeAgent.toPrecision(8) * 100) / 100
          this.selectedArea[indexArea].selectedItem[indexItem].agentItem2[`volume${indexArea}`] = Math.round(volumeAgent2.toPrecision(8) * 100) / 100
        } else if (agent_item_id && !agent_item_2_id) {
          const ratioAgent = this.selectedArea[indexArea].selectedItem[indexItem].agentItem1.liter
          const multiplier = ratioItem + ratioAgent
          const volumeAgent = volume * (ratioAgent / multiplier)

          this.selectedArea[indexArea].selectedItem[indexItem].agentItem1[`volume${indexArea}`] = Math.round(volumeAgent.toPrecision(8) * 100) / 100
        }
      }

      this.getTotalVol(indexArea)
    },
    getTotalArea(indexArea) {
      return this.selectedArea[indexArea].luasArea
    },
    getTotalVol(indexArea) {
      let totalVolume = 0

      this.selectedArea[indexArea].selectedItem.forEach(el => {
        totalVolume += Number(el[`volume${indexArea}`])
      })

      this.selectedArea[indexArea].totalVol = totalVolume

      this.getAllVol()
    },
    getAllVol() {
      let totalVol = 0

      this.selectedArea.forEach((el, indexArea) => {
        if (el.name.toUpperCase() !== 'FREE ITEM') {
          totalVol += parseFloat(el.totalVol)
        } else {
          el.selectedItem.forEach(item => {
            if (item[`volume${indexArea}`]) {
              totalVol += parseFloat(item[`volume${indexArea}`])
            }
          })
        }
      })

      this.quotationData.total_volume = totalVol
    },
    getTotalHarga(indexArea) {
      let totalHarga = 0
      let totalHargahpp = 0
      let totalDFT = 0

      this.selectedArea[indexArea].selectedItem.forEach(el => {
        if (el[`total${indexArea}`]) {
          totalHarga += parseFloat(el[`total${indexArea}`])
        }

        if (el[`totalhpp${indexArea}`]) {
          totalHargahpp += parseFloat(el[`totalhpp${indexArea}`])
        }

        if (el[`dft${indexArea}`]) {
          totalDFT += parseFloat(el[`dft${indexArea}`])
        }
      })

      this.selectedArea[indexArea].total = totalHarga
      this.selectedArea[indexArea].totalhpp = totalHargahpp
      this.selectedArea[indexArea].totalDFT = totalDFT

      this.getSubtotal()
    },
    changeDiscountMode(discount) {
      this.selectedDiscount = discount
      this.discount = '0'
      this.quotationData.diskon = 0
      this.getSubtotal()
    },
    getSubtotal() {
      let total = 0
      let totalhpp = 0

      this.selectedArea.forEach((el, indexArea) => {
        if (el.name.toUpperCase() !== 'FREE ITEM') {
          if (el.total) {
            total += parseFloat(el.total)
          }

          if (el.totalhpp) {
            totalhpp += parseFloat(el.totalhpp)
          }
        } else {
          el.selectedItem.forEach(item => {
            totalhpp += parseFloat(item[`totalhpp${indexArea}`])
            total += parseFloat(item[`total${indexArea}`])
          })
        }
      })

      this.quotationData.subtotal = total
      this.quotationData.totalhpp = totalhpp

      if (this.discount) {
        if (this.selectedDiscount === '%') {
          const discount = ((total / 100) * parseFloat(this.discount))
          this.quotationData.total = total - discount
          this.quotationData.diskon = discount
        } else {
          const discount = Number(this.discount)
          this.quotationData.total = total - discount
          this.quotationData.diskon = discount
        }
      } else {
        this.discount = '0'
        this.quotationData.diskon = 0
        this.quotationData.total = total
      }
    },
    submitQuote({ draft }) {
      let errorCount = 0

      if (!this.quotationData.dueDate) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Harap isi tanggal berlaku!',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })

        errorCount += 1
      }

      if (this.selectedArea.length < 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Harap pilih area dan item terlebih dahulu!',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })

        errorCount += 1
      }

      this.selectedArea.forEach((area, index) => {
        if (area.selectedItem < 1) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Area ${area.name} belum memiliki item, harap pilih item terlebih dahulu`,
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
          errorCount += 1
        } else {
          area.selectedItem.forEach((item, indexItem) => {
            let { liter } = item

            if (item.agentItem1) {
              liter += item.agentItem1.liter
            }

            if (item.agentItem2) {
              liter += item.agentItem2.liter
            }

            if (
              +item[`volume${index}`] % liter !== 0
              && (area.name.toLowerCase() === 'anchor' || area.name.toLowerCase() === 'free item' || +area.luasArea > 1)
            ) {
              errorCount += 1
              this.$refs.validateQuote.errors[`Volume ${index + 1} - ${indexItem}`].push('Volume harus sesuai kelipatan packaging')
            }
          })
        }
      })

      if (errorCount === 0) {
        this.$refs.validateQuote.validate().then(success => {
          if (success) {
            this.loadingAdd = true

            const areas = []

            this.selectedArea.forEach((area, indexArea) => {
              const items = []

              area.selectedItem.sort((a, b) => a.sort - b.sort)

              area.selectedItem.forEach(item => {
                items.push({
                  item_id: item.id,
                  item_name: item.name,
                  item_code: item.no,
                  liter: item[`volume${indexArea}`] ? parseFloat(item[`volume${indexArea}`]) : 0,
                  thinner_code: item?.thinner?.no ?? null,
                  price: parseFloat(item[`price${indexArea}`]) ?? 0,
                  hpp: parseFloat(item[`hpp${indexArea}`]) ?? null,
                  subtotal: item[`total${indexArea}`] ? item[`total${indexArea}`] : 0,
                  subtotal_hpp: +item[`totalhpp${indexArea}`] ? parseFloat(item[`totalhpp${indexArea}`]) : 0,
                  coat: parseFloat(item[`coat${indexArea}`]) ?? null,
                  dry_film_thickness: +item[`dft${indexArea}`] ? +item[`dft${indexArea}`] : null,
                  theoritical: parseFloat(item[`theory${indexArea}`]) ?? 0,
                  practical: parseFloat(item[`practical${indexArea}`]) ?? 0,
                  loss: parseFloat(item[`loss${indexArea}`]) ?? 0,
                  lapis_uid: item.lapis_uid ? item.lapis_uid : null,
                })
              })

              areas.push({
                area_category: area.id,
                surface_area: Number(area.luasArea),
                total_volume: parseFloat(area.totalVol),
                total: parseFloat(area.total),
                total_hpp: area.totalhpp,
                surface_preparation: area.selectedSurface.map(el => el.id).join(','),
                notes: area.notes,
                items,
              })
            })

            const payload = {
              project_id: this.quotationData.project.id,
              is_draft: draft,
              valid_until: new Date(this.quotationData.dueDate),
              notes: this.quotationData.notes,
              discount: parseFloat(this.quotationData.diskon),
              total_volume: parseFloat(this.quotationData.total_volume),
              total: parseFloat(this.quotationData.total),
              total_hpp: parseFloat(this.quotationData.totalhpp),
              areas,
            }

            if (this.reviseMode) {
              this.$store.dispatch('reviseQuote', {
                quote_id: +this.id,
                is_draft: draft,
                params: {
                  valid_until: new Date(this.quotationData.dueDate),
                  notes: this.quotationData.notes,
                  discount: parseFloat(this.quotationData.diskon),
                  total_volume: parseFloat(this.quotationData.total_volume),
                  total: parseFloat(this.quotationData.total),
                  total_hpp: parseFloat(this.quotationData.totalhpp),
                  areas,
                },
              }).then(result => {
                if (result.data.addNewVersionQuote.status === 'success') {
                  this.loadingAdd = false
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Berhasil direvisi!',
                      icon: 'EditIcon',
                      variant: 'success',
                    },
                  })
                  this.resetState()
                  this.$router.replace({ name: 'quotation-detail', params: { id: this.id.toString(), version: (+this.version + 1).toString() } })
                }
              }).catch(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Gagal menyimpan!',
                    icon: 'EditIcon',
                    variant: 'danger',
                  },
                })
                this.loadingAdd = false
              })
            } else if (this.editMode) {
              this.$store.dispatch('updateQuote', {
                quote_id: +this.id,
                params: {
                  valid_until: new Date(this.quotationData.dueDate),
                  notes: this.quotationData.notes,
                  discount: parseFloat(this.quotationData.diskon),
                  total_volume: parseFloat(this.quotationData.total_volume),
                  total: parseFloat(this.quotationData.total),
                  total_hpp: parseFloat(this.quotationData.totalhpp),
                  areas,
                },
              }).then(result => {
                if (result.data.updateQuote.status === 'success') {
                  this.loadingAdd = false
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Berhasil diupdate!',
                      icon: 'EditIcon',
                      variant: 'success',
                    },
                  })
                  this.resetState()
                  this.$router.replace({ name: 'quotation-detail', params: { id: this.id.toString(), version: this.version.toString() } })
                }
              }).catch(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Gagal menyimpan!',
                    icon: 'EditIcon',
                    variant: 'danger',
                  },
                })
                this.loadingAdd = false
              })
            } else {
              this.$store.dispatch('addQuote', payload).then(result => {
                if (result.data.addQuote.status === 'success') {
                  this.loadingAdd = false
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Berhasil disimpan!',
                      icon: 'EditIcon',
                      variant: 'success',
                    },
                  })
                  this.resetState()
                  this.$router.replace({ name: 'quotation-detail', params: { id: result.data.addQuote.id.toString(), version: '1' } })
                }
              }).catch(err => {
                if (err.errors[0].message === 'Token has been expired or revoked.') {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Berhasil disimpan!',
                      icon: 'EditIcon',
                      variant: 'success',
                    },
                  })
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Token Gmail anda expired, silakan login ulang',
                      icon: 'EditIcon',
                      variant: 'danger',
                    },
                  })
                  // this.$router.replace({ name: 'quotation-detail', params: { id: result.data.addQuote.id.toString(), version: '1' } })
                  // this.resetState()
                  this.loadingAdd = false
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Gagal menyimpan!',
                      icon: 'EditIcon',
                      variant: 'danger',
                    },
                  })
                  this.loadingAdd = false
                }
              })
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Harap lengkapi data terlebih dahulu!',
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
          }
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Data tidak valid, harap cek kembali',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
      }
    },
    preventDelete(map, vm) {
      return {
        ...map,
        8: e => {
          if (!vm.search.length) {
            e.preventDefault()
            this.handleDeselectedArea(this.selectedArea[this.selectedArea.length - 1])
          }
        },
      }
    },
    findSetLiter(item, raw = false) {
      if (item.agentItem1 && item.agentItem2) {
        const liter = item.liter + item.agentItem1.liter + item.agentItem2.liter
        return raw ? liter : `Set ${liter} Liter`
      }

      if (item.agentItem1) {
        const liter = item.liter + item.agentItem1.liter
        return raw ? liter : `Set ${liter} Liter`
      }

      if (item.type.id === 3) {
        return item.liter
      }

      return raw ? item.liter : ''
    },
    replaceAllLoss(indexArea) {
      this.selectedArea[indexArea].selectedItem.forEach((item, indexItem) => {
        if (item.type.id === 1) {
          this.selectedArea[indexArea].selectedItem[indexItem][`loss${indexArea}`] = this.selectedArea[indexArea].loss
        }
      })

      this.changeStuff()
    },
    resetState() {
      this.loading = false
      this.loadingAdd = false
      this.selectedDiscount = '%'
      this.discount = ''
      this.quotationData = {
        id: '0000',
        issuedDate: new Date(),
        dueDate: moment(new Date()).add(1, 'M').format('YYYY-MM-DD'),
        customer: {},
        project: {},
        subtotal: 0,
        subtotalhpp: 0,
        total: 0,
        totalhpp: 0,
        diskon: 0,
        notes: '',
      }
      this.loadingAreaOptions = false
      this.areaOptions = []
      this.selectedArea = []
      this.selectedAreaVModel = []
      this.prevQuote = ''
      this.error = ''
    },
    confirmReset() {
      this.$swal({
        title: 'Reset Form?',
        text: 'Yakin ingin reset form? Progress pengisian form anda akan hilang.',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn text-danger btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.resetState()
        }
      })
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.dirtyForm) {
      this.$swal({
        title: 'Ingin keluar?',
        text: 'Yakin ingin keluar? Progress pengisian form anda belum disimpan.',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn text-danger btn-outline-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          if (this.editMode) {
            this.resetState()
          }

          next()
        }
      })
    } else {
      next()
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.custom-shadow {
  box-shadow: rgba(177, 177, 177, 0.1) 0px 8px 20px !important;
}

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
