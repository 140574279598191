<template>
  <vue-bottom-sheet
    ref="previewPdf"
    :click-to-close="false"
    block
    max-width="90%"
    class="overflow-hidden pdfsheet"
  >
    <div
      class="bottom-sheet pb-2"
      style="height: 90vh"
    >
      <div class="d-flex justify-content-between mb-1 align-content-center">
        <h4>Preview Dokumen</h4>
        <feather-icon
          icon="XIcon"
          size="28"
          class="text-primary"
          @click="$refs.previewPdf.close(); pdfLink = ''"
        />
      </div>
      <hr>
      <div style="height: 90%;">
        <vue-pdf-app
          v-if="pdfLink"
          :pdf="pdfLink"
          :file-name="fileName"
        />
      </div>
    </div>
  </vue-bottom-sheet>
</template>

<script>
import VueBottomSheet from '@webzlodimir/vue-bottom-sheet'
import VuePdfApp from 'vue-pdf-app'
import 'vue-pdf-app/dist/icons/main.css'

export default {
  components: {
    VueBottomSheet,
    VuePdfApp,
  },
  data() {
    return {
      pdfList: [],
      pdfLink: '',
      fileName: 'Quotation',
    }
  },
  methods: {
    show(data, fileName) {
      if (fileName) {
        this.fileName = fileName
      } else {
        // eslint-disable-next-line prefer-destructuring
        this.fileName = data.substring(data.lastIndexOf('/') + 1).split('.')[0]
      }
      this.pdfLink = data
      this.$refs.previewPdf.open()
    },
  },
}
</script>

<style>
.bottom-sheet {
  margin: 16px;
}

.pdfsheet .bottom-sheet__content[data-v-6fad203c] {
  overflow-y: hidden !important;
}
</style>
