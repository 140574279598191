export const validatorPositive = value => {
  if (value >= 0) {
    return true
  }
  return false
}

export const validatorPassword = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)
  return validPassword
}

export const validatorCreditCard = creditnum => {
  /* eslint-disable no-useless-escape */
  const cRegExp = /^(?:3[47][0-9]{13})$/
  /* eslint-enable no-useless-escape */
  const validCreditCard = cRegExp.test(creditnum)
  return validCreditCard
}

export const validatorUrlValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
  /* eslint-enable no-useless-escape */
  return re.test(val)
}

export const validateTwoDecimal = val => {
  /* eslint-disable no-useless-escape */
  const regExp = /^\d+(?:\.\d{1,2})?$/
  /* eslint-enable no-useless-escape */
  return regExp.test(val)
}

export const validateVol = (_, args) => {
  const area = args[0]
  const volume = args[2]
  const liter = args[1]
  const items = args[3]
  if (+items === 45 || +items === 2) {
    if (+volume % +liter === 0) return true
    return false
  }
  if (+area < 1) return false
  if (+volume % +liter !== 0) return false
  return true
}
