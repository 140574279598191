<template>
  <div>
    <div
      v-if="loadingScheme"
      class="d-flex"
    >
      <b-skeleton
        width="100%"
        height="40px"
        class="mb-0"
      />
    </div>
    <vue-autosuggest
      v-else
      v-model="item"
      :suggestions="filteredOptions"
      :input-props="inputProps"
      :limit="10"
      :component-attr-id-autosuggest="`${isSchemeEmpty ? 'schemeEmpty' : ''}${isItemEmpty ? 'itemEmpty' : ''}`"
      :section-configs="sectionConfigs"
      @input="onInputChange"
    >
      <template slot="before-suggestions">
        <div
          v-if="isSchemeEmpty && isItemEmpty"
          class="d-flex justify-content-center align-items-center p-1 w-100"
          @mouseup.stop
        >
          <h6 class="text-muted font-weight-normal mb-0">
            Scheme atau item tidak ditemukan
          </h6>
        </div>
      </template>
      <template slot="before-input">
        <div
          class="d-flex justify-content-between"
          @mouseup.stop
        >
          <label>Cari Scheme atau Item</label>
          <b-spinner
            v-if="loading"
            class="mr-25"
            small
            variant="primary"
          />
        </div>
      </template>
      <template slot-scope="{suggestion}">
        <div
          v-if="suggestion.name == 'scheme'"
          class="cursor-pointer d-flex"
        >
          <b-avatar
            :style="suggestion.item.color ? `background: #${suggestion.item.color.hex_code} !important;` : ''"
            variant="light-primary"
            icon="circle"
            text=""
          />
          <div class="ml-50">
            <h6 class="m-0">
              {{ suggestion.item.name }}
            </h6>
            <small>{{ suggestion.item.notes }}</small>
          </div>
        </div>
        <div
          v-if="suggestion.name == 'item' && suggestion.item.type"
          class="cursor-pointer d-flex"
        >
          <b-avatar
            :style="suggestion.item.type.id === 1 ? `background: #${resolveColor(suggestion.item.color)} !important;` : ''"
            :variant="suggestion.item.type.id === 1 ? 'light-primary' : suggestion.item.type.id === 2 ? 'secondary' : 'light-secondary'"
            :icon="suggestion.item.type.id === 1 ? 'circle' : null"
            :text="suggestion.item.type.id === 1 ? '' : suggestion.item.type.id === 2 ? 'AG' : 'TH'"
          />
          <div class="ml-50">
            <h6 class="m-0">
              {{ suggestion.item.name }}
            </h6>
            <small>{{ suggestion.item.no }} - {{ suggestion.item.itemCategory.name }} {{ suggestion.item.liter ? `- ${suggestion.item.liter} Liter` : null }}</small>
          </div>
        </div>
      </template>
    </vue-autosuggest>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { VueAutosuggest } from 'vue-autosuggest'
import { BAvatar, BSpinner, BSkeleton } from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { mapGetters } from 'vuex'
import { items } from '@/graphql/query'

export default {
  components: {
    VueAutosuggest,
    BAvatar,
    BSpinner,
    BSkeleton,
  },
  data() {
    return {
      loading: false,
      avatarText,
      item: '',
      filteredOptions: [],
      inputProps: {
        id: `autosuggest__input_scheme_item_${Math.random()}`,
        class: 'form-control',
        placeholder: 'Cari scheme atau item...',
      },
      limit: 10,
      sectionConfigs: {
        scheme: {
          label: 'Scheme',
          onSelected: selected => {
            this.onSelected(selected)
          },
        },
        item: {
          label: 'Items',
          onSelected: selected => {
            if (selected) {
              this.onSelected(selected)
            }
          },
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      schemeSuggest: 'getSchemeList',
      loadingScheme: 'getLoadingScheme',
    }),
    isSchemeEmpty() {
      return this.filteredOptions.length ? this.filteredOptions[0].data[0] === 'empty' : false
    },
    isItemEmpty() {
      return this.filteredOptions.length ? this.filteredOptions[1]?.data[0] === 'empty' : false
    },
  },
  async mounted() {
    if (this.schemeSuggest.length < 1) {
      await this.$store.dispatch('getScheme')
    }

    this.filteredOptions = [
      { name: 'scheme', data: this.schemeSuggest },
      { name: 'item', data: ['empty'] },
    ]
  },
  methods: {
    resolveColor(color) {
      if (color) {
        return color.hex_code ? color.hex_code : '#FFF'
      }

      return '#FFF'
    },
    onSelected(option) {
      if (option) {
        this.$emit('onSelected', option)
        this.item = ''
      }
    },
    onInputChange(text) {
      if (text.length > 2) {
        if (this.timeout) {
          clearTimeout(this.timeout)
        }

        this.timeout = setTimeout(async () => {
          this.loading = true

          const filteredScheme = this.schemeSuggest.filter(item => {
            if (
              text !== ''
              && !item.name?.toLowerCase()?.includes(text.toLowerCase())
            ) {
              return false
            }

            if (item.status === 0) {
              return false
            }

            return true
          })

          const keyword = text ? text.toLowerCase() : null

          const filteredItem = await this.$apollo.query({
            query: items,
            context: {
              headers: {
                Authorization: this.$store.getters.getBearerToken,
              },
            },
            variables: {
              filter: {
                search: keyword,
                item_type: [1],
              },
            },
            fetchPolicy: 'no-cache',
          })

          this.filteredOptions = [
            { name: 'scheme', data: filteredScheme.length > 0 ? filteredScheme : ['empty'] },
            { name: 'item', data: filteredItem.data.items.items.length > 0 ? filteredItem.data.items.items : ['empty'] },
          ]
          this.loading = false
        }, 1000)
      } else {
        this.filteredOptions = [
          { name: 'scheme', data: this.schemeSuggest },
          { name: 'item', data: ['empty'] },
        ]
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';

#schemeEmptyitemEmpty .autosuggest__results-before--scheme,
#schemeEmptyitemEmpty-autosuggest__results .autosuggest__results-before--item,
#schemeEmptyitemEmpty .autosuggest__results-item,
#schemeEmpty .autosuggest__results-before--scheme,
#schemeEmpty #autosuggest__results-item--0,
#itemEmpty-autosuggest__results .autosuggest__results-before--item ,
#itemEmpty [data-section-name="item"] {
  display: none !important;
}
</style>
