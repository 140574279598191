<template>
  <div>
    <div
      v-if="loading && !initialCustomer.length && role === 1"
      class="d-flex"
    >
      <b-skeleton
        width="100%"
        height="40px"
        class="mb-0"
      />
    </div>
    <vue-autosuggest
      v-else
      v-model="selected"
      :suggestions="filteredOptions"
      :input-props="inputProps"
      :limit="10"
      :component-attr-id-autosuggest="filteredOptions.length && filteredOptions[0].data[0] === 'empty' ? 'CustomerEmpty' : 'AutoSuggestCustomer'"
      @selected="onSelected"
      @input="onInputChange"
    >
      <template slot="before-input">
        <div class="loader-suggestion">
          <b-spinner
            v-if="loading"
            small
            variant="primary"
          />
        </div>
      </template>
      <template slot="before-suggestions">
        <div
          v-b-toggle.sidebar.sidebar-right
          class="d-flex justify-content-between align-items-center cursor-pointer p-1 add-button"
          @mouseup.stop
        >
          <h6 class="text-primary m-0">
            Tambah Customer Baru
          </h6>
          <feather-icon
            icon="PlusIcon"
            size="24"
            class="text-primary"
          />
        </div>
        <div
          v-if="filteredOptions.length && filteredOptions[0].data[0] === 'empty'"
          class="d-flex justify-content-between align-items-center p-1"
          @mouseup.stop
        >
          <h6 class="text-muted font-weight-normal">
            {{ assignedMode ? 'Tidak ditemukan atau belum ada customer yang diassignkan' : loading ? 'Tunggu sebentar yaa...' : 'Cari customer...' }}
          </h6>
        </div>
      </template>
      <template slot-scope="{suggestion}">
        <div
          v-if="suggestion.item.name"
          class="d-flex align-items-center cursor-pointer"
        >
          <b-avatar
            size="40px"
            variant="light-primary"
            :text="avatarText(suggestion.item.name)"
          />
          <div class="ml-50">
            <h6 class="m-0">
              {{ suggestion.item.name }}
            </h6>
            <small class="text-muted d-block">
              {{ suggestion.item.customerNo }}
            </small>
            <small>
              <b-badge
                v-if="suggestion.item.category"
                variant="light-primary"
              >
                {{ suggestion.item.category }}
              </b-badge>
            </small>
          </div>
        </div>
        <div
          v-else
          class="d-none"
        />
      </template>
    </vue-autosuggest>

    <b-sidebar
      id="sidebar-right"
      ref="sidebarRight"
      lazy
      title="Tambah Customer"
      right
      shadow
      backdrop
      backdrop-variant="transparent"
      class="sidebar"
      @shown="fetchSomeStuff()"
    >
      <div class="px-2 py-1">
        <validation-observer ref="validateCustomer">
          <b-form @submit.prevent>
            <b-form-group
              label="Kode"
            >
              <validation-provider
                ref="kodePelanggan"
                #default="{ errors }"
                name="Kode"
                rules="required|max:10"
              >
                <b-form-input
                  ref="inputKode"
                  v-model="userData.kode"
                  placeholder="Kode Pelanggan"
                  :state="errors.length > 0 ? false : errorCustomerNo ? false : userData.kode && !loadingCheckCustomerNo ? true : null"
                  @change="checkCustomerNo"
                />
                <small
                  class="text-danger d-block mt-25"
                  v-text="errorCustomerNo"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Nama"
            >
              <validation-provider
                #default="{ errors }"
                name="Nama"
                rules="required|min:2|max:50"
              >
                <b-form-input
                  v-model="userData.nama"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Nama Pelanggan"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Kategori Harga"
            >
              <validation-provider
                #default="{ errors }"
                name="Kategori Harga"
                rules="required"
              >
                <v-select
                  v-model="userData.kategori_harga"
                  label="name"
                  :options="priceCategory"
                  placeholder="Kategori Harga"
                  :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="email"
              >
                <b-form-input
                  v-model="userData.email"
                  placeholder="Email Pelanggan"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Limit Piutang"
            >
              <validation-provider
                #default="{ errors }"
                name="Limit"
                rules="integer|required|min_value:0|max_value:999999999999"
              >
                <b-input-group prepend="Rp.">
                  <cleave
                    v-model="userData.limitPiutang"
                    class="form-control"
                    :raw="true"
                    placeholder="Limit Piutang"
                    :options="cleaveFormat.limitPiutang"
                  />
                </b-input-group>
                <small class="text-danger d-block">{{ errors[0] }}</small>
                <small class="text-secondary">Minimal 100.000, maksimal 999.999.999.999</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Payment Term"
            >
              <validation-provider
                #default="{ errors }"
                name="Payment Term"
                rules="required"
              >
                <v-select
                  v-model="userData.paymentTerm"
                  label="name"
                  :options="paymentTerm"
                  placeholder="Payment Term"
                  :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Default Diskon"
            >
              <validation-provider
                #default="{ errors }"
                name="Diskon"
                rules="required|positive|min_value:0|max_value:100|twodecimal"
              >
                <b-input-group append="%">
                  <b-form-input
                    v-model="userData.defaultDiskon"
                    type="number"
                    placeholder="Default Diskon"
                  />
                </b-input-group>
                <small class="text-danger d-block">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              class="mt-1"
              variant="primary"
              @click="loadingAdd ? null : addCustomer()"
            >
              <span v-if="!loadingAdd">Submit</span>
              <b-spinner
                v-else
                small
              />
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              variant="secondary"
              @click="resetForm"
            >
              Reset
            </b-button>
          </b-form>
        </validation-observer>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest'
import {
  BAvatar, BSidebar, VBToggle, BFormInput, BButton, BForm,
  BFormGroup, BInputGroup, BSpinner, BBadge, BSkeleton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, min, max, email, length, positive, minValue, maxValue,
} from '@validations'
import { avatarText } from '@core/utils/filter'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VueAutosuggest,
    BAvatar,
    BSidebar,
    BFormInput,
    BButton,
    BForm,
    BFormGroup,
    BInputGroup,
    BSpinner,
    BSkeleton,
    BBadge,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    Cleave,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    value: {
      type: [Object, String],
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    filterUser: {
      type: Number,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      required,
      min,
      max,
      email,
      length,
      positive,
      minValue,
      maxValue,
      avatarText,
      filteredOptions: [],
      inputProps: {
        id: `autosuggest_customer_${Math.random()}`,
        class: 'form-control',
        placeholder: 'Cari customer...',
        disabled: this.disabled,
      },
      limit: 10,
      data: '',
      cleaveFormat: {
        limitPiutang: {
          numeral: true,
        },
      },
      userData: {
        kode: '',
        nama: '',
        limitPiutang: 0,
        email: '',
        kategori_harga: '',
        paymentTerm: '',
        defaultDiskon: '0',
      },
      paymentTerm: [],
      errorCustomerNo: '',
      loading: false,
      loadingCheckCustomerNo: true,
      loadingAdd: false,
      initialCustomer: [],
    }
  },
  computed: {
    role() {
      return this.$root.role
    },
    ...mapGetters({
      allCustomer: 'getCustomerList',
      assignedCustomer: 'getCustomerAssigned',
      customerCategory: 'getCategoryCustomer',
      priceCategory: 'getPriceCategoryCustomer',
    }),
    selected: {
      get() {
        let value = ''

        if (this.value) {
          const customerList = this.role === 1 ? this.assignedCustomer : this.allCustomer
          const preselected = customerList.filter(el => el.id === this.value.id)

          /* eslint-disable no-nested-ternary */
          value = preselected.length > 0 ? preselected[0].name : this.value.name
        }

        return value
      },
      set(val) {
        this.data = val
      },
    },
    assignedMode() {
      if (this.filterUser) return true
      if (this.role === 2) return false
      return true
    },
  },
  mounted() {
    if (!this.initialCustomer.length && this.assignedMode) {
      this.loading = true
      this.$store.dispatch('getCustomerAssigned', { id: this.filterUser || this.$store.state.user.currentUser.user.id }).then(result => {
        this.initialCustomer = result
        this.filteredOptions = [{
          data: result.length ? result : ['empty'],
        }]
        this.loading = false
      })
    }
  },
  methods: {
    fetchSomeStuff() {
      this.$store.dispatch('getCustomerCategory')
      this.$store.dispatch('getPriceCategory')
      this.$store.dispatch('getPaymentTerm').then(result => {
        this.paymentTerm = result
        // eslint-disable-next-line prefer-destructuring
        this.userData.paymentTerm = result[0]
      })
      if (this.$store.state.reference.selectedDB.id === 0) {
        this.userData.kategori_harga = {
          id: 50,
          name: 'Umum',
        }
      }
    },
    checkCustomerNo(data) {
      this.loadingCheckCustomerNo = true
      this.$store.dispatch('checkCustomerNo', data).then(result => {
        if (!result) {
          this.errorCustomerNo = 'Kode ini sudah dipakai. Harap gunakan kode yang lain.'
        } else {
          this.errorCustomerNo = ''
        }

        this.loadingCheckCustomerNo = false
      })
    },
    onSelected(option) {
      if (option) {
        this.$emit('onSelected', option.item)
      } else {
        this.$store.commit('setSelectedCustomer', '')
      }
    },
    onInputChange(text) {
      if (text.length > 2) {
        if (this.timeout) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(async () => {
          this.loading = true
          if (!this.assignedMode) {
            this.$store.dispatch('getCustomers', {
              filter: `{
                search: "${text}",
              }`,
              pagination: `{
                limit: 20,
                offset: 1,
              }`,
            }).then(result => {
              this.filteredOptions = [{
                data: result.customers.length ? result.customers : ['empty'],
              }]

              this.loading = false
            }).catch(() => {
              this.loading = false
            })
          } else {
            console.log(text.toString())
            this.$store.dispatch('getCustomerAssigned', {
              id: this.filterUser || this.$store.state.user.currentUser.user.id,
              search: text.toString(),

            }).then(result => {
              this.filteredOptions = [{
                data: result.length ? result : ['empty'],
              }]

              this.loading = false
            }).catch(() => {
              this.loading = false
            })
            const filteredData = this.initialCustomer.filter(item => {
              if (
                text !== ''
              && !item.name?.toLowerCase()?.includes(text.toLowerCase())
              ) {
                return false
              }

              return true
            })
            this.filteredOptions = [{
              data: filteredData.length > 0 ? filteredData : ['empty'],
            }]
          }
        }, 1000)
      } else {
        this.filteredOptions = [{
          data: this.assignedMode ? this.initialCustomer : ['empty'],
        }]

        this.loading = false
      }
    },
    addCustomer() {
      this.$refs.validateCustomer.validate().then(async success => {
        if (success) {
          this.loadingAdd = true
          await this.$store.dispatch('addCustomer', {
            customerNo: this.userData.kode,
            name: this.userData.nama,
            email: this.userData.email,
            customerLimit: parseFloat(this.userData.limitPiutang),
            priceCategoryName: this.userData.kategori_harga.name,
            termName: this.userData.paymentTerm.name,
            defaultSalesDisc: parseFloat(this.userData.defaultDiskon),
          }).then(async value => {
            if (value.status === 'success') {
              this.$store.dispatch('getCustomerDetail', value.id).then(data => {
                this.loadingAdd = false
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Berhasil disimpan!',
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
                this.$refs.sidebarRight.hide()
                this.$store.commit('pushCustomer', data)
                if (this.assignedMode) {
                  this.initialCustomer.push(data)
                }
                this.filteredOptions = [{
                  data: [data],
                }]
                this.onSelected({
                  item: data,
                })
                this.resetForm()
              }).catch(() => {
                this.loadingAdd = false
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Gagal menyimpan!',
                    icon: 'EditIcon',
                    variant: 'danger',
                  },
                })
              })
            }
          }).catch(() => {
            this.loadingAdd = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Gagal menyimpan!',
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Harap lengkapi data terlebih dahulu!',
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
    resetForm() {
      this.userData = {
        kode: '',
        nama: '',
        limitPiutang: 0,
        email: '',
        kategori_harga: {
          id: 50,
          name: 'Umum',
        },
        paymentTerm: '',
        defaultDiskon: '0',
      }
      this.$refs.validateCustomer.reset()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
@import '~@core/scss/base/components/variables-dark';

.add-button:hover {
  background: #eee;

  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}

#CustomerEmpty #autosuggest__results-item--0 {
  display: none !important;
}

.b-sidebar {
  background-color: #fff !important;

  .dark-layout & {
    background-color: $theme-dark-body-bg !important;
  }

  width: 360px;
}

.form-control {
  .dark-layout & {
    color: #fff !important;
  }
}

.loader-suggestion {
  position: absolute;
  top: 0;
  right: 12px;
}
</style>
